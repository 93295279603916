import { React,useEffect,useState } from "react";
import HeaderComponent from "../components/HeaderKDS";
import { Layout, Row, Col } from "antd";
import { getApitoken,getKitchenkds } from "../module/kichtenCDS/kitchencds";
const { Content } = Layout;
function Cds() {
  // Sample orders data (replace this with actual data from API)
  const orders = [
    { id: 1, name: "1115534564" },
    { id: 2, name: "11879462" },
    { id: 3, name: "1455454513" },
    // Add more orders as needed
  ];
  const [openOrders, setOpenOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [apiToken, setApiToken] = useState("");

  const getAPIToken = async () => {
    const res = await getApitoken();
    if (res.status === 200) {
      setApiToken(res.data.message.data.api_token);
      const openResponse = await getKitchenkds('Open', res.data.message.data.api_token);
      const completedResponse = await getKitchenkds('Completed', res.data.message.data.api_token);
      if (openResponse.status === 200) {
        setOpenOrders(openResponse.data.message);
      }
      if (completedResponse.status === 200) {
        setCompletedOrders(completedResponse.data.message);
      }
    } else {
      console.log("Error in getting the API Token");
    }
  };

  useEffect(() =>{
    getAPIToken();
  },[])

  const renderOrders = (orders) => {
    const ordersPerLine = 7;
    const orderRows = [];
    for (let i = 0; i < orders.length; i += ordersPerLine) {
      orderRows.push(
        <div className="order-row" key={i}>
          {orders.slice(i, i + ordersPerLine).map((order) => (
            <div key={order.order_id} className="order-box">
              {order.order_id}
            </div>
          ))}
        </div>
      );
    }
    return orderRows;
  };

  return (
    <>
      <HeaderComponent welcomeMessage="Good Morning, thank you for choosing Kleo " />
      <Content>
        <div className="main-container">
          <div className="inner-container">
            <div className="sub-container">
              <h2>
                <span>Ready</span>
              </h2>
              {renderOrders(completedOrders)}
            </div>
            <div className="sub-container in-kitchen">
              <h2>
                <span>In The Kitchen</span>
              </h2>
              {renderOrders(openOrders)}
            </div>
          </div>
        </div>
      </Content>
    </>
  );
}

export default Cds;
