import React from "react";
import HeaderInnerComp from "./Header";
import Footer from "./Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <HeaderInnerComp />
      <div className="container privacy-policy">
        <h1>KLEO Privacy Policy </h1>
        <p>
          <strong>Updated Privacy as of 11th October 2023 </strong>
        </p>

        <div class="privacy-items">
          <p>
            Welcome to our website. If you continue to browse and use this
            website, you agree to comply with and be bound by the following
            terms and conditions of use, which, together with our privacy
            policy, govern Kleo (“we,” “our,” or “us”) relationship with you
            about this website. Please do not use our website if you disagree
            with any of these terms and conditions.
          </p>
          <p>
            We are committed to protecting the privacy and security of your
            personal information. This Privacy Policy explains how we collect,
            use, and share your data when you use our services. We regularly
            update this Privacy Policy to ensure you are always informed about
            how we collect, hold, and manage your data. Please read this &nbsp; 
            <a href="/privacy" aria-current="page" class="link-3 w--current">
              Privacy Policy
            </a>
            , as well as our &nbsp; 
             <a href="/terms-and-condition" class="link-3">
              Terms and Conditions.
            </a>
          </p>
          <p>
            'Personal data' means any information relating to an identified or
            identifiable natural person ('data subject'); an identifiable
            natural person can be identified, directly or indirectly, in
            particular by reference to an identifier such as a name, an
            identification number, location data, an online identifier or to one
            or more factors specific to the physical, physiological, genetic,
            mental, economic, cultural or social identity of that natural
            person.
          </p>
          <p>
            'Personal information', as a term in this Privacy Policy, may not
            necessarily constitute 'personal data', as it may not be
            identifiable data, per the above definition.
          </p>
          <h4>The type of personal information we collect </h4>
          <p>
            For a better experience while using our Service, we may require you
            to provide us with certain personally identifiable information,
            including but not limited to the following:
          </p>
          <ul class="list">
            <li>Name</li>
            <li>Date of birth</li>
            <li>Gender</li>
            <li>Profession</li>
            <li>Contact information</li>
            <li>Demographic information</li>
            <li>Financial information</li>
            <li>IP address</li>
            <li>Browser type</li>
            <li>Operating system</li>
            <li>Dietary information</li>
            <li>Drinking habits</li>
          </ul>
          <p>
            The information that we request will be retained by us and used as
            described in this privacy policy.
          </p>
          <h4>How do we get personal information, and why do we have it </h4>
          <p>
            We generally process your information when we need to do so to
            fulfil a contractual obligation (for example, to process your
            payments via the Kleo platform) or when we or someone we work with
            needs to use your personal information for a reason related to their
            business (for example, to provide you with a service). Other reasons
            we ask you to provide us with your data include:
          </p>
          <ul class="list">
            <li>registering for an account on our website</li>
            <li>signing up for our marketing emails</li>
            <li>purchasing services</li>
            <li>processing information to improve our services</li>
            <li>to notify you about changes to our terms and conditions</li>
            <li>
              to comply with the requirements of the payment operator platforms
            </li>
          </ul>
          <p>
            The app uses third-party services that may collect information to
            identify you.
          </p>
          <p>
            Link to the privacy policy of third-party service providers used by
            the app:
          </p>
          <ul class="list color">
            <li>
              <div>
                <a
                  href="https://play.google.com/about/play-terms/"
                  class="link-3"
                >
                  Google Play Services
                </a>
              </div>
            </li>
            <li>
              <div class="text-block-3">
                <a
                  href="https://policies.google.com/technologies/partner-sites"
                  target="_blank"
                  rel="noreferrer"
                  class="link-3"
                >
                  Google Analytics for Firebase
                </a>
              </div>
            </li>
            <li>
              <div class="text-block-4">
                <a
                  href="https://firebase.google.com/support/privacy"
                  target="_blank"
                  rel="noreferrer"
                  class="link-3"
                >
                  Firebase Crashlytics
                </a>
              </div>
            </li>
            <li>
              <div>
                <a
                  href="https://m.facebook.com/about/privacy"
                  target="_blank"
                  rel="noreferrer"
                  class="link-3"
                >
                  Facebook
                </a>
              </div>
            </li>
          </ul>
          <h4>Log Data </h4>
          <p>
            We inform you that whenever you use our Service, in a case of an
            error in the app, we collect data and information (through third
            party products) on your phone called Log Data. This Log Data may
            include information such as your device's Internet Protocol (“IP”)
            address, device name, operating system version, the app's
            configuration when utilising our Service, the time and date of your
            use of the Service, and other statistics.
          </p>
          <h4>Marketing our products and services to you </h4>
          <p>
            Upon registering with us, we will be using your data to communicate
            with you about our services and products. You may unsubscribe at any
            time from our mailing list.
          </p>
          <h4>Cookies </h4>
          <p>
            Cookies are files with a small amount of data commonly used as
            anonymous unique identifiers. These are sent to your browser from
            the websites you visit and stored on your device's internal memory.
          </p>
          <p>
            This Service does not use these “cookies” explicitly. However, the
            app may use third-party code and libraries that use “cookies” to
            collect information and improve their services. You can either
            accept or refuse these cookies and know when a cookie is being sent
            to your device. If you choose to refuse our cookies, you may not be
            able to use some portions of this Service.
          </p>
          <h4>
            How we store your personal information <br />
            Cross-border data transfers
          </h4>
          <p>
            We are a United Kingdom-based company that works with and processes
            data about individuals worldwide. We may send your personal
            information outside your state, province, or country to operate our
            business, including the United States. If we store or process your
            data outside of the UK, we will comply with the provisions of the
            Data Protection Act 2018 and GDPR and other guidance issued by the
            EU for data transfers. When we send your information across borders,
            we take steps to protect your information, and we try to only send
            your information to countries with strong data protection laws, as
            required per the “adequacy rulings” concerning data transfers.
          </p>
          <h4>Your data protection rights </h4>
          <p>
            You should be able to access and control your personal information
            no matter where you live. Depending on how you use Kleo, you may
            have the right to request access to, correct, amend, delete, port to
            another service provider, or restrict or object to specific uses of
            your personal information (for example, direct marketing). We will
            only charge you a little or provide you with a different level of
            service if you exercise any of these rights.
          </p>
          <p>
            If you buy something from a Kleo-powered store and wish to exercise
            these rights over information about your purchase, you must contact
            the merchant you interacted with directly. We are only a processor
            on their behalf and cannot decide how to process their information.
            As such, we can only forward your request to them to allow them to
            respond. Of course, we will help our merchants fulfil these requests
            by giving them the tools to answer their questions.
          </p>
          <p>
            Please note that we must ensure you respond if you send us a request
            relating to your personal information. To do so, we may ask to see
            documentation verifying your identity, which we will discard after
            verification.
          </p>
          <p>
            If you would like to designate an authorised agent to exercise your
            rights, please email us from our email address on file. If you email
            us from a different email address, we cannot determine if the
            request is coming from you and will not accommodate your request.
            Please include your authorised agent's name and email address in
            your email.
          </p>
          <p>
            If you are not happy with our response to a request, you can contact
            us to resolve the issue. You also have the right to contact your
            local data protection or privacy authority at anytime.
          </p>
          <p>
            Finally, because there is no common understanding of what a Do not
            Track Signal supposed to mean, we don't respond to those signals in
            any particular way.
          </p>
          <p>
            If you are not happy with our response to a request, you can contact
            us to resolve the issue. You also have the right to contact your
            local data protection or privacy authority at anytime.
          </p>
          <h4>
            Contact Us <br />
            How to complain
          </h4>
          <p>
            If you have any concerns about our use of your personal information,
            you can complain to us at
            <a href="mailto:support@kleocloud.com" class="link-3">
              support@kleocloud.com
            </a>
          </p>
          <p>
            Or by writing to Kleo Limited, <br />
            Unit 3 Newdown Farm, Micheldever, Winchester,
            <br />
            England, SO21 3BT.
          </p>
          <p>
            You can also complain to the ICO if you are unhappy with how we have
            used your data.
          </p>
          <p>
            The ICO's address: <br />
            Information Commissioner's Office <br />
            Wycliffe House <br />
            Water Lane <br />
            Wilmslow <br />
            Cheshire <br />
            SK9 5AF <br />
            Helpline number: 0303 123 1113{" "}
          </p>
          <p>
            ICO website:
            <a href="https://www.ico.org.uk" class="link-3">
              https://www.ico.org.uk
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
