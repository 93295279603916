import React from "react";
import HeaderInnerComp from "./Header";
import Footer from "./Footer";

const TermsAndConditions = () => {
  return (
    <>
      <HeaderInnerComp />

      <div className="container privacy-policy">
        <h1>Terms and Conditions</h1>

        <p>
          This Customer Agreement (the "Agreement") sets out the terms and
          conditions (the "Terms") by which Kleo Cloud Limited ("we", "our",
          "Kleo" or the "Company") provides access to our Cloud Software and/or
          Hardware Services, which you ("you", "your", "the User" or "the
          Customer") purchase and/or subscribe to. Each a "Party" and
          collectively the "Parties" to this Agreement.
        </p>
        <p>
          Please read these Terms and Conditions carefully before using the
          Software and/or Hardware provided by Kleo Cloud Limited.
        </p>
        <ol>
          <li>
            <h4>Terms of Use</h4>
            <ol>
              <li>
                By using any company services, you agree to be bound by the
                Terms herein. If you do not agree to any of these Terms, do not
                use any company services.
              </li>
              <li>
                These terms and conditions apply to all users who receive access
                to Cloud Software and/or Hardware services provided by Kleo,
                irrespective of whether the users pay a subscription fee or not.
                Any other terms, conditions, or representations (other than
                those made fraudulently or implied by statute) are excluded.
              </li>
              <li>
                We reserve the right, at any time, to amend, modify, alter, or
                update the terms and conditions of this agreement. We will
                endeavour to notify the users of such changes via email or from
                our App. If you continue to use the Company's services, your use
                will be governed by the updated terms.
              </li>
              <li>
                No agency, partnership, joint venture, or employment is created
                as a result of these Terms, and the User does not have any
                authority of any kind to bind the Company in any respect
                whatsoever.
              </li>
              <li>
                Subject to the terms herein, all rights conferred by these Terms
                are granted to the User in a food serving business. No part of
                the Software may be used for other purposes, including, but not
                limited to, research, study, competitor analysis or any other
                activity prohibited by this agreement.
              </li>
              <li>
                By agreeing to the terms and conditions herein the User warrants
                that they are not and will not be deemed a ‘consumer' as defined
                in The Consumer Contracts (Information, Cancellation and
                Additional Charges) Regulations 2013.
              </li>
            </ol>
          </li>

          <li>
            <h4>Definitions and Interpretations </h4>
            <ol>
              <li>
                Kleo Cloud Limited is a software development company and not a
                website development consultancy firm. As a SaaS company (“SaaS”
                stands for Software as a Service), we host an application(s) and
                make it (them) available to customers over the internet. This
                infers that the software sits on a SaaS company's server while
                the user accesses it remotely. We do not offer any website
                development and/or custom application development services.
              </li>
              <li>
                Kleo Services shall refer to any Cloud Software services,
                including, but not limited to, Electronic Point of Sales (ePOS),
                Self-ordering kiosks, web ordering, mobile apps ordering and QR
                Code ordering, and/or any Hardware services provided to the User
                by the Company (each a “Service” and collectively the
                “Services”).
              </li>
              <li>
                “Consumer” and/or “End User” refers to the client of the User
                who is making a purchase for food services from the User via our
                Platform.
              </li>
              <li>
                “Business Day” is a day other than a Saturday, Sunday, or public
                holiday in England when banks in London are open for business.
                “Business Hours” refers to the hours between 9am and 5pm on a
                Business Day.
              </li>
              <li>
                “Platform” means the technology (hardware and software) provided
                by Kleo, as a SaaS company, to facilitate food ordering. The
                products and services that we provide through our Platform,
                regardless of whether fees are charged, are also referred to as
                “Platform Services”.
              </li>
              <li>
                A “Platform Fee” means a fee paid by the End User to Kleo, under
                the Platform Fees Model (Section 12), in consideration for each
                individual transaction processed via the Kleo Platform, in
                addition to any order and delivery fees. Platform Fees are not
                Merchant Fees or Platform Charges.
              </li>
              <li>
                A “Platform Charge” means a fee paid by the User to Kleo, in
                consideration for each individual transaction processed using
                web ordering, mobile apps ordering and/or QR Code ordering
                services under the Platform Charges Model (Section 13). Platform
                Charges are not Platform Fees.
              </li>
              <li>
                A “License Subscription Fee” is the minimum monthly/annual fee,
                if any, paid by the User to Kleo for access to our Cloud
                Software.
              </li>
              <li>
                “Payment Service Providers” connect the User (the User may also
                be referred to as a “Merchant” with relation to any/all payment
                processing) to their consumers, card brand networks and
                financial institutions to deliver a simple payment experience
                for users and their customers by processing payments quickly and
                efficiently. Payment Service Providers may provide both a
                merchant account and a payment gateway. A “Merchant Account” is
                a type of business bank account that allows a business to accept
                and process electronic payment card transactions. A “Payment
                Gateway” is a technology used by merchants to accept debit or
                credit card purchases from customers. The term includes not only
                the physical card-reading devices found in brick-and-mortar
                retail stores but also the payment processing portals found in
                online stores. Kleo may charge the User “Merchant Fees” for
                any/all payment processing services they provide to the User to
                accept payments from end users. Merchant Fees are not Platform
                Fees. For further information on Payment Service Providers and
                payment processing, please refer to Sections 14 and 15.
              </li>
              <li>
                Users who have integrated Kleo payments powered by our partners
                as their Payment Service”. The User is required to agree to the
                terms and conditions of a Our partners Agreement to create a
                Connected Account.
              </li>
              <li>
                “Personal Data” means any information relating to an identified
                or identifiable natural person (“Data Subject”); an identifiable
                natural person is one who can be identified, directly or
                indirectly, in particular by reference to an identifier such as
                a name, an identification number, location data, an online
                identifier or to one or more factors specific to the physical,
                physiological, genetic, mental, economic, cultural or social
                identity of that natural person.
              </li>
              <li>
                “Confidential Information” shall mean certain information and
                materials, either marked “confidential” or “proprietary” or that
                a reasonable business person would expect to be confidential or
                proprietary, including but not limited to information relating
                to a disclosing party's or its Group's business, operations and
                products, trade secrets, methodologies, potential products,
                services, processes, customers, business relationships,
                marketing materials, strategic plans, ideas, research data,
                development, costs, technologies, financial information and
                pricing information.
              </li>
              <li>
                “Virus” refers to any device or thing (including any software,
                code, file or programme) which may: prevent, impair or otherwise
                adversely affect the operation of any computer software,
                hardware or network, any telecommunications service, equipment
                or network or any other service or device; prevent, impair or
                otherwise adversely affect access to or the operation of any
                programme or data, including the reliability of any programme or
                data (whether by rearranging, altering or erasing the programme
                or data in whole or part or otherwise); or adversely affect the
                user experience, including worms, Trojan horses, viruses and
                other similar things or devices.
              </li>
              <li>
                Section headings shall not affect the interpretation of these
                Terms.
              </li>
              <li>
                Unless the context otherwise requires, words in the singular
                shall include the plural, and in the plural shall include the
                singular.
              </li>
            </ol>
          </li>
          <li>
            <h4> Account Information</h4>
            <ol>
              <li>
                You must be at least 18 years old to purchase and/or subscribe
                for a license to access and use our Cloud Software and/or
                Hardware services.
              </li>
              <li>
                You agree to provide accurate, current and complete information
                during the subscription process and to update such information
                to keep it accurate, current and complete. Any significant
                updates to the User's personal account information, governed by
                the Data Protection Act 2018, shall be requested in writing to
                support@kleocloud.com with a valid reason for the requested
                changes. Kleo, in its sole discretion and where deemed
                necessary, reserves the right to request proof of any changes
                with relation to personal data.
              </li>
              <li>
                We reserve the right to suspend or terminate your subscription
                and your access to our Cloud Software Services if any
                information provided during the subscription process or
                thereafter proves to be inaccurate, not current or incomplete.
              </li>
              <li>
                Once terminated, the Company shall permanently delete (or
                restrict the processing of) the User's account and all data
                associated with it. The Company shall not be held responsible
                for the User's account data after termination.
              </li>
            </ol>
          </li>
          <li>
            <h4> Data Protection and Security</h4>
            <ol>
              <li>
                The Company endeavours to use reasonable security measures to
                keep the User's personal data highly confidential and protect
                the User against unauthorised access to their account, account
                information, and any data which may be stored by the Company
                Cloud Software. However, the Company cannot guarantee absolute
                security of a user's account, account information and/or data,
                and the Company cannot promise that the security measures will
                prevent third-party “attackers” from illegally accessing the
                Services or their content. The User acknowledges that their
                submission of any information on or through the Services is at
                the User's own risk and the User accepts all risks of
                unauthorised access to the Services, account information and any
                other information provided to the Company or through the
                Software.
              </li>
              <li>
                For the avoidance of confusion, the User shall typically
                function as the "Data Controller" for any personal data they
                supply to Kleo to perform our services. While the data
                controller will determine the motives and means of processing
                personal data, the data processor will process the data on
                behalf of the data controller. Therefore, Kleo is a "Data
                Processor" that processes personal data on behalf of the Data
                Controller while they are using the Kleo services. Any such data
                controlled by the User shall be governed by the Data Protection
                Act 2018. Kleo shall not be held liable for any breach in data
                protection legislation by the User.
              </li>
              <li>
                Kleo, in its capacity of a Data Processor, can only process data
                currently stored on the Cloud Software. Any data permanently
                modified or deleted from the Cloud Software or the User's
                personal account, cannot be retrieved and Kleo carries no
                responsibilities with relation to any such previous data.
              </li>
              <li>
                Data controllers will hold the responsibility for the
                implementation of relevant technical and organisational measures
                to substantiate and demonstrate that their data is processed in
                compliance with the Data Protection Act 2018. All obligations
                regarding concepts such as lawfulness, transparency and
                fairness, data minimisation, purpose limitation, and
                preciseness, as well as fulfilling data subject requests lie
                with the Data Controller.
              </li>
              <li>
                Kleo does not have any kind of authority over the data
                protection notices, policies, and terms and conditions of the
                User. Therefore, the User will forfeit and keep Kleo and its
                affiliates indemnified against all losses, costs, and
                liabilities and all expenses incurred, including reasonable
                legal or other professional expenses, arising out of or in
                connection with any claim regarding:
                <ol>
                  <li>a breach to the Data Protection Act 2018, or</li>
                  <li>
                    any whatsoever liability emerging in respect of the cookies,
                    or
                  </li>
                  <li>
                    the capture of Personal Data through the User's website(s),
                    or
                  </li>
                  <li>
                    the agreement of data subjects for dispersing any Personal
                    Data outside of the European Economic Area by Kleo.
                  </li>
                  <li>
                    Any liability under this subsection will not be subject to
                    any limitation of liability or exclusions of liability under
                    the Agreement.
                  </li>
                </ol>
              </li>
              <li>
                Kleo shall inform the Data Controller without any undue delay,
                or within seven (7) days, upon Kleo or any sub-processor observe
                a breach impacting personal data. At this point in time Kleo
                will provide the Customer with all adequate information required
                to meet any obligation so as to inform the concerned data
                protection authority or to inform the victim under applicable
                Data Protection laws.
              </li>
              <li>
                Kleo shall concur to guide the User with all subject access
                requests that may be obtained from the end-customer within an
                appropriate time limit (at the User's cost). Kleo shall ensure
                that adequate technical and organisational measures are in
                place, so that the User can meet its obligations to those
                requesting access to their personal data held by Kleo. The
                Company's assistance with regards to subject access requests is
                limited to any data currently stored by the User on the Cloud
                Software. Kleo will not be able to retrieve any old data
                previously modified or deleted from the Cloud.
              </li>
              <li>
                The User hereby consents that Kleo may engage any of its
                subcontractors as sub-processors without the requirement of
                further consent. Therefore, the subcontractors are considered as
                approved under this clause. The User may request a full list of
                current sub-processors at any time.
              </li>
              <li>
                The Company shall not be held liable for any losses arising out
                of the unauthorised use of a User's account and the User agrees
                to defend, indemnify and hold the Company harmless, including
                the officers, directors, shareholders, employees, distributors,
                agents, partners, licensors, information providers and account
                providers, as applicable, from and against any improper,
                unauthorised or illegal uses of the User's account.
              </li>
              <li>
                The User's login credentials may only be used by the User.
                Sharing a login with other people is not permitted. An
                organisation may create separate logins for as many individual
                users as it wishes. Each user is responsible for maintaining the
                security of their account login and passwords. Each user agrees
                that they will not disclose their password to any third party
                and that they will take sole responsibility for any activities
                or actions under the User's account, whether or not you have
                authorised such activities or actions.
              </li>
              <li>
                The user agrees to immediately notify the Company of any
                unauthorised use of their account, or any other breach of
                security at support@kleocloud.com
              </li>
              <li>
                Right to Erasure or Restriction of Processing: Section 47 of the
                Data Protection Act 2018. The User reserves the right to request
                an erasure of all their personal data from the Kleo user account
                records, should the User wish to do so once this agreement is
                terminated. Any such Right to Erasure request must be
                communicated to Kleo in writing to support@kleocloud.com In
                cases where personal data must be maintained for the purposes of
                evidence, Kleo shall (instead of erasing the personal data)
                restrict its processing. In addition, as a Data Controller, it
                is the User's responsibility to comply with any Right to Erasure
                requests received by the User from any of their customers
                regarding data stored on the Cloud Software. Kleo, as a Data
                Processor, carries no responsibility with regards to any Right
                to Erasure requests received by the User from any of their
                customers.
              </li>
              <li>
                In addition to the terms of this Agreement, and Section 4 in
                particular, the User should read and understand the terms of the
                Company's Privacy Policy and Cookie Policy for further
                information on how Kleo collects and processes personal
                information. By using our services, the User automatically
                agrees to the Company's Privacy Policy and Cookie Policy.
              </li>
            </ol>
          </li>
          <li>
            <h4> Kleo Hardware</h4>
            <ol>
              <li>
                The User may purchase hardware from Kleo with or without a valid
                Cloud Software license subscription. Where the User purchases
                our hardware for use with a different software, Kleo cannot
                guarantee that the hardware shall be compatible with the User's
                software. The Company is not responsible for ensuring the User's
                software compatibility to our hardware.
              </li>
              <li>
                It is the User's responsibility to research fully whether any
                hardware purchased from Kleo shall meet their requirements and
                expectations. All hardware descriptions and some video
                demonstrations are available online at
                https://www.Kleocloud.com/. Should the User require any further
                information, the User should contact our Team. The User is
                encouraged to make full use of these resources in order to make
                an informed decision when purchasing our hardware.
              </li>
              <li>
                All hardware purchases require a full upfront payment at the
                time of placing an order for dispatch.
              </li>
              <li>
                All prices are listed and charged to the User's bank account in
                GBP (£). Any charges for transactions in foreign currency, other
                than GBP (£), shall be payable by the User.
              </li>
              <li>
                All hardware comes flat packed with instruction manuals/guides
                to help the User carry out full assembly of the equipment. The
                Company shall not be sending any engineers to carry out any
                installation or troubleshooting at the User's location at any
                time.
              </li>
              <li>
                All hardware provided by the Company comes with one (1) year
                basic manufacturing warranty from the delivery dispatch date.
                <ol>
                  <li>
                    In the event of a reasonable technical fault, the Company
                    shall endeavour to troubleshoot any issues remotely, or
                    request for the faulty hardware to be returned via the
                    Warranty Returns Process in Section 20.
                  </li>
                  <li>
                    The Company reserves the right to repair the same faulty
                    piece of equipment as many times as needed before offering a
                    replacement or refund. The Company shall offer a replacement
                    or refund only if the faulty equipment is non-repairable.
                  </li>
                  <li>
                    Replacement hardware may be a refurbished piece of equipment
                    with the same or better technical specification of the
                    original hardware.
                  </li>
                  <li>
                    Upon replacement, the warranty remains one (1) year from the
                    initial delivery dispatch date, not from the dispatch date
                    of the replacement item.
                  </li>
                  <li>
                    Theft, fire, tampering and mishandling is not covered under
                    basic manufacturing warranty.
                  </li>
                  <li>
                    The Company is not liable if the hardware becomes unusable
                    due to third-party software including, but not limited to,
                    manufacturers and operating systems. No repair, replacement
                    or refund shall be offered to the User in any such cases.
                  </li>
                </ol>
              </li>
              <li>
                The Company, its subsidiaries, affiliates, and its licensors, do
                not warrant that:
                <ol>
                  <li>
                    The Hardware will meet the User's specific requirements; or
                  </li>
                  <li>
                    The quality of the software, hardware and any other material
                    purchased or obtained by the User will meet all the User's
                    expectations; or
                  </li>
                  <li>
                    The Hardware will never develop any faults, errors and/or
                    inaccuracies.
                  </li>
                  <li>
                    The Hardware will be compatible with newer versions of the
                    Cloud Software, and/or any other software, for the duration
                    of the lifetime of the Hardware purchased. At some point in
                    the future, and it is impossible to predict with exact
                    certainty when this may happen, after the expiration of the
                    one (1) year basic manufacturing warranty, the Hardware may
                    become obsolete. The Company shall endeavour to inform the
                    User, within reasonable time, should the Company stop
                    providing technical support and software updates for a
                    particular version of the Hardware.
                  </li>
                </ol>
              </li>
              <li>
                The User shall obtain appropriate insurance to cover all
                hardware equipment, any other equipment, belongings and property
                against fire, theft, accidental damage and any other damage
                occurring due to an unlikely failure in any hardware provided by
                Kleo. The Company shall not be held responsible for any loss of
                profits, business, goodwill, use, data or other intangible and
                tangible losses resulting from a failure in any hardware
                provided by Kleo.
              </li>
              <li>
                Portable Appliance Testing (PAT) of all hardware equipment
                should be carried out by an individual deemed competent to do
                so. This is usually a trained individual with a valid
                Certificate of Competency in PAT testing. It is the User's
                responsibility to ensure all hardware equipment is PAT tested
                regularly to comply with UK laws and regulations. Kleo shall not
                be responsible for carrying out PAT tests or reminding the User
                of the need to carry out regular PAT testing.
              </li>
              <li>
                The Customer acknowledges and agrees that all hardware purchases
                made through Kleo are non-refundable.
              </li>
              <li>
                Once an order for hardware is placed and payment is received, it
                is considered final, and no refunds or returns will be accepted.
              </li>
            </ol>
          </li>
          <li>
            <h4> Cloud Software</h4>
            <ol>
              <li>
                The User can purchase our Cloud Software (the “Software”)
                license subscription with or without hardware provided by Kleo.
                Should the User decide not to purchase hardware from Kleo, the
                User agrees that they have access to hardware fully compatible
                with our Cloud Software. Where the User does not purchase our
                hardware, the Company shall not be held responsible if the
                software fails to work. The Company is not responsible for
                ensuring the User's hardware compatibility to our Cloud
                Software.
              </li>
              <li>
                Our Cloud Software requires a suitable, fast, stable and
                reliable internet connection to ensure full and effective
                functionality of the Software. Kleo recommends the use of wired
                internet connection for better connectivity, although this
                recommendation does not guarantee performance of the User's
                internet provider.
              </li>
              <li>
                The Software is provided on an "as is" and "as available" basis.
                The Software is provided without warranties of any kind, whether
                express or implied, including, but not limited to, implied
                warranties of merchantability, fitness for a particular purpose,
                non-infringement or course of performance. The use of the
                Software is at the User's sole risk.
              </li>
              <li>
                The Company shall endeavour to promptly address any technical
                glitches encountered while using the software, providing such
                glitches are brought to the Company's attention immediately.
              </li>
              <li>
                The Company, its subsidiaries, affiliates, and its licensors, do
                not warrant that:
                <ol>
                  <li>
                    The Software will meet the User's specific requirements; or
                  </li>
                  <li>
                    The quality of the software, hardware and any other material
                    purchased or obtained by the User will meet all the User's
                    expectations; or
                  </li>
                  <li>The Software will always be accurate or reliable; or</li>
                  <li>
                    The Software will be uninterrupted, timely, secure, or
                    error-free; or
                  </li>
                  <li>
                    The Software is free of viruses or other harmful components;
                    or
                  </li>
                  <li>Any/all errors in the Software will be corrected.</li>
                </ol>
              </li>
              <li>
                The Software contains generic settings for all aspects including
                taxations, promotions and menu setup. These are customisable as
                per the User's needs. It is the User's responsibility to update
                all necessary “Settings” before using the Software.
              </li>
              <li>
                In the event that the User is required to download any other
                software in connection with the Cloud Software, the User shall
                be unable to download and access such software unless they first
                agree to the license agreement relating to that software. Use of
                any such software is governed by the terms of use for any such
                license agreement.
              </li>
              <li>
                The User shall upload all menus, promotions and all other
                necessary data into the Cloud Software. The Company solely
                provides guidance on the procedure of uploading data onto the
                Software but the User holds the responsibility to upload the
                data themselves.
              </li>
              <li>
                All data uploaded by the User onto the Cloud Software is stored
                for the duration of the license subscription. The User shall
                download all data they wish to save before the license
                subscription is terminated. Once the license subscription is
                terminated, all data is permanently deleted. There is no
                possible way to retrieve deleted data.
              </li>
              <li>
                Any data deleted, at any time, from the Cloud Software cannot be
                restored.
              </li>
              <li>
                The Company shall take backups, at least once every 24 hours, of
                all customers' data and keep a copy. However, it is recommended
                that users themselves take regular backups of their data from
                the Cloud Software
              </li>
              <li>
                In the event of an internet connection malfunction, Kleo can run
                certain Software features offline. During such times, all data
                shall be stored on the User's hardware. However, the User needs
                to connect to the internet within thirty (30) days of the start
                of offline trading in order to transfer all data stored on the
                device to the Cloud Software. If no internet connection is
                established within thirty (30) days of the start of offline
                trading, all or some data will be lost and/or the system may
                stop working.
              </li>
              <li>
                To access the full features of the Software, the User shall
                ensure a suitable, fast, stable and reliable internet
                connection.
              </li>
              <li>
                Should the User require any additional new functionality of the
                Software, the User may submit a request in writing to the
                Company for assessment. The Company shall, within reasonable
                time, endeavour to review any such request and assess the
                compatibility of such new functionality within the Company's
                services. The Company does not guarantee that it will be
                possible to implement any additional new functionality within
                the Company's services. In addition, all design and/or
                functionality requests with potential of copyright infringement,
                with regards to a competitor company, shall be rejected.
              </li>
              <li>
                Kleo currently uses a Cloud server facility located in the
                country of Ireland, with an additional backup server facility in
                Singapore. Both of these locations are subject to change based
                on financial motives or risk to the data stored. Kleo shall
                review these server facility locations as deemed needed by the
                Company. The Company shall not be required to obtain the User's
                consent should the Company decide to move the Cloud server
                facility to a new location.
              </li>
              <li>
                Cloud servers have 99% uptime. Should there be any planned
                maintenance of the servers, the Company shall endeavour to
                notify the User in advance of such planned maintenance. In the
                unlikely event of an unplanned disruption to the server
                functionality, the Company shall not be held liable by the User
                for any loss of profits, business, goodwill, use, data or other
                intangible and tangible losses resulting from such server
                failure.
              </li>
            </ol>
          </li>
          <li>
            <h4>License Subscription Fees</h4>
            <ol>
              <li>
                The Company has several pricing options and subscription levels
                depending on the User's requirements. Before making any
                purchase, we recommend exploring all license subscription
                levels, pricing and limitations. The User shall select a
                subscription level based on their needs. By using the Software,
                the User accepts the terms and limitations of the selected
                subsection level and this Agreement.
              </li>
              <li>
                Once the User has completed the full payment and registration
                process, the User shall receive full access to the User's online
                customer account.
              </li>
              <li>
                Alternatively, Users can request a quotation via the website and
                a member of our dedicated Kleo Product Gurus shall be in contact
                to discuss and generate a “Quotation” in writing to the User's
                email address as specified on their account information.
              </li>
              <li>
                The User shall pay the selected monthly/annual subscription fee,
                if any, for the Software provided by Kleo on the same date of
                each calendar month/year, unless cancelled as per the
                Cancellation Policy in Section 23.
              </li>
              <li>
                Based on the User's current license subscription level and cost,
                the subscription will be renewed monthly/annually via Direct
                Debit or credit/debit card recurring. There are no other payment
                options available unless agreed upon during the onboarding
                process.
              </li>
              <li>
                If the User's payment fails and no payment is done within seven
                (7) days of initial payment failure, the license subscription
                will be cancelled, and data associated with it will be deleted
                permanently from the Cloud after thirty (30) days of the initial
                payment failure.
              </li>
              <li>
                Should the User run out of Cloud data storage, the User can
                either upgrade their license subscription or download all/some
                data onto a secure device and delete it from their Cloud. The
                User acknowledges and accepts that any deleted data from their
                Cloud cannot be retrieved.
              </li>
              <li>
                The User may Upgrade or Downgrade their subscription at any time
                via the Subscription tab on their online customer account.
                <ol>
                  <li>
                    Annual subscriptions may be upgraded or downgraded by giving
                    us one calendar month notice.
                  </li>
                  <li>
                    If downgrading an annual subscription, a refund shall be
                    issued for the monetary difference in subscription levels
                    within twenty-eight (28) days of receiving the request to
                    downgrade and functionality changes shall be processed upon
                    expiration of the month notice.
                  </li>
                  <li>
                    If upgrading an annual subscription, any monetary difference
                    in subscription levels can be paid via credit/debit card
                    immediately and functionality changes will become active
                    within twenty-four (24) hours.
                  </li>
                  <li>
                    Kleo shall endeavour to process monthly subscription changes
                    from the following billing date proving we have received at
                    least three (3) working days' notice. No refunds are
                    available for downgrading a monthly subscription.
                  </li>
                  <li>
                    Downgrade option is not applicable if the User has
                    subscribed to the lowest subscription level provided by
                    Kleo.
                  </li>
                  <li>
                    Once downgraded or upgraded, the monthly/annual payment will
                    be adjusted, and Direct Debit changed accordingly, from the
                    next billing date. Any subscription changes made within
                    three (3) working days of the next billing date, shall be
                    reflected from the billing date after next.
                  </li>
                </ol>
              </li>
              <li>
                The fees charged by the Company for use of the Software and/or
                Hardware are subject to change. The Company reserves the right
                to increase the subscription fee every year after the first
                twelve (12) months of the subscription by either 5% of the
                current subscription charges, or as per the inflation rate
                recognised by the UK Office of National Statistics, whichever is
                greater.
                <ol>
                  <li>
                    The Company will endeavour to notify the User at least 30
                    days prior to increasing the subscription fees.
                  </li>
                  <li>
                    The User's continued use of the Software will be deemed
                    acceptance of the renewed terms of payment.
                  </li>
                  <li>
                    The User reserves the right to cancel their subscription,
                    should the increased subscription cost be outside the User's
                    financial abilities. The User needs to exercise this right
                    as per the process outlined in Section 23. Should the User
                    wish to re-subscribe after their subscription has been
                    cancelled, a new Quotation and Terms shall be generated.
                  </li>
                </ol>
              </li>
              <li>
                All prices are listed and charged to the User's bank account in
                GBP (£). Any charges for transactions in foreign currency, other
                than GBP (£), shall be payable by the User.
              </li>
              <li>
                All invoices for monthly/annual payments and/or individual
                purchases shall be available for the User to view via their
                online customer account. The Company shall not be emailing
                invoices for individual payments.
              </li>
              <li>
                This Section 7 is not applicable to Users with a Quotation for a
                “Free Subscription” (please refer to Section 22).
              </li>
            </ol>
          </li>
          <li>
            <h4>
              Payment Terminal Rental, Software License Duration and Renewal
            </h4>
            <ol>
              <li>
                The Customer acknowledges that the subscription and rental for
                Kleo's software & payment terminals is for a fixed term of two
                (2) years from the date of initiation, as agreed upon during the
                subscription process and onboarding process.
              </li>
              <li>
                The license and rental will automatically renew at the end of
                the initial term and subsequent terms unless the Customer
                provides explicit written notice of cancellation to
                support@kleocloud.com at least thirty (30) days prior to the
                renewal date.
              </li>
              <li>
                The license and terminal granted herein is non-transferable and
                non-exclusive.
              </li>
              <li>
                The Customer may use the licensed software and rented terminals
                solely for their internal business operations.
              </li>
              <li>
                The Customer shall not sublicense, distribute, rent, lease, or
                otherwise transfer the software and payment terminals to any
                third party.
              </li>
              <li>
                The customer on on-boarding and setting up the direct debit
                acknowledges their agreement to either purchase or lease the
                terminal from Kleo Cloud Limited for a fixed duration of two (2)
                years. If the customer chooses to terminate the contract, they
                will incur charges for the remaining duration if cancellation
                occurs before the contract's conclusion.
              </li>
            </ol>
          </li>
          <li>
            <h4> Web Ordering Services</h4>
            <ol>
              <li>
                Kleo provides a web ordering system which comes with a URL and
                it cannot be changed.
              </li>
              <li>
                Where the User already has their own existing website, the User
                shall incorporate the URL into their business website.
              </li>
              <li>
                Kleo web ordering platform designs are pre-built and we do not
                make any customisations.
              </li>
              <li>
                This Section 9 is applicable to Users with a subscription to our
                Web Ordering software services.
              </li>
            </ol>
          </li>
          <li>
            <h4> Mobile Apps Ordering Services</h4>
            <ol>
              <li>
                Kleo provides mobile apps for Google Play Store and Apple Store
                suitable for mobile devices only. These mobile apps are with
                “Pre-built” designs and we do not make any customisations.
              </li>
              <li>
                If the User wishes to submit an app(s) to publish via their own
                Google and Apple accounts, the User is free to do so themselves.
                As per Data Protection regulations, Kleo will not be able to
                access the User's own mobile apps control panels.
              </li>
              <li>
                If the User does not have their own Google and/or Apple accounts
                or does not wish to use their own Google and/or Apple accounts,
                the User may opt in to submit the app(s) for publishing to
                Google Play Store and/or Apple Store via Kleo Cloud Limited's
                business account. If the User opts in to submit the app(s) to
                Google Play Store and/or Apple Store via Kleo Cloud Limited's
                business account, the User has an obligation to provide
                necessary information such as, but not limited to, business
                description, app description, terms and conditions, refund
                policy and privacy policy, and keep this information accurate,
                current and complete.
              </li>
              <li>
                Kleo Cloud Limited does not have any control over the app
                approval process. Google Play Store and Apple Store approve app
                submissions under their sole discretion. They may change their
                policies without any prior notice to anyone and may reject app
                submissions for any reason. If a submission is rejected, the
                User shall provide necessary information and evidence as
                requested by Google Play Store and/or Apple Store to resubmit
                the app(s).
              </li>
              <li>
                Google Play Store and Apple Store usually take up to seven (7)
                business days to approve apps. These timelines can change at any
                time and Kleo Cloud Limited does not have any control over these
                timelines.
              </li>
              <li>
                Any necessary amendments to the mobile app(s) content, design,
                images and graphics shall be done by the User.
              </li>
              <li>
                This Section 10 is applicable to Users with a subscription to
                our Mobile Apps Ordering software services.
              </li>
            </ol>
          </li>
          <li>
            <h4>QR Code Ordering Services</h4>
            <ol>
              <li>
                Kleo provides a QR Code ordering platform which comes with a URL
                and this URL cannot be changed.
              </li>
              <li>
                Our QR Code platform designs are “Pre-built” and we do not make
                any customisations.
              </li>
              <li>
                The User has an obligation to provide necessary information such
                as, but not limited to, terms and conditions, refund policy and
                privacy policy, and keep this information accurate, current and
                complete, in order for Kleo to be able to develop a QR Code for
                ordering.
              </li>
              <li>
                Any necessary amendments to the QR Code content, design, images
                and graphics shall be done by the User.
              </li>
              <li>
                Kleo shall provide a QR Code in JPEG or PNG format which will be
                sent through email. The User shall print their own stationery
                and/or marketing materials to advertise the QR Code ordering
                system. Kleo shall not be providing the User any stationery
                and/or marketing materials to advertise the QR Code ordering
                system at any time.
              </li>
              <li>
                This Section 11 is applicable to Users with a subscription to
                our QR Code Ordering software services.
              </li>
            </ol>
          </li>
          <li>
            <h4>Platform Fees Model</h4>
            <ol>
              <li>
                The Platform Fees Model helps Users by passing the cost of
                maintaining web, mobile and QR Code ordering technologies to the
                End User.
              </li>
              <li>
                The Platform Fees Model is applicable to Users who have signed
                up for a subscription to at least one of the following Services:
                Web Ordering, Mobile Apps Ordering and/or QR Code Ordering. The
                Platform Fees Model requires the User to integrate Kleo Payments
                powered by Our partners as their Payment Service Provider. This
                Model is not available with any other payment service provider.
              </li>
              <li>
                Platform Fees are not Merchant Fees. Platform Fees are charged
                to the End User, while Merchant Fees are payable to Kleo by the
                User depending on the payment type.
              </li>
              <li>
                The Platform Fee shall have VAT included in it, even if the User
                is not VAT registered. If an End User requires a VAT receipt for
                the full value of their order, the End User should contact the
                User for a receipt detailing the order and delivery fees, and
                contact separately Kleo in writing to  finance@Kleo.com with
                their request for a receipt detailing the Platform Fee. Kleo
                shall endeavour to provide a VAT invoice within seven (7)
                business days.
              </li>
              <li>
                The User may request Kleo to provide them with data relating to
                Platform Fees charged. Kleo is under no obligation to provide
                any such information, but if we do provide any information, it
                shall be cumulative data for a given period of time. Should Kleo
                agree to provide such data, the Company shall endeavour to
                complete the request within twenty-eight (28) calendar days.
              </li>
            </ol>
          </li>
          <li>
            <h4>Kleo Payments Powered by Our partners</h4>
            <ol>
              <li>
                Kleo has partnered with various acquirers to provide a Payment
                Processing service. It should be noted that Kleo is not a
                Payment Service Provider, rather merely an integrator of Our
                partners payment processing tools. For the avoidance of
                confusion, it should be noted that Kleo is not regulated by FCA
                as a payment service provider. This Section is only applicable
                to Users who have signed up for this payment processing.
              </li>
              <li>
                To accept payments using our software, the User is required to
                apply for a registration with our partners. The User may be
                asked to provide certain documents, including, but not limited
                to, identity documents, business registration documents and
                address proof. If the User (irrespective of whether the User is
                a business or an individual) is rejected by Our partners to
                accept the payments, the User will not be able to use our App.
                Kleo has no control over Our partners' selection process.
              </li>
              <li>
                The User shall be charged by Kleo a Merchant Fee per payment
                processed via the Platform based on a pre-agreed fixed
                percentage. The Merchant Fee percentages payable to Kleo and the
                money settlement frequency shall be communicated to the User
                during the sales/registration process or the Quotation process.
              </li>
              <li>
                Kleo shall provide the User with a “Payment Dashboard” with
                minimum transaction information for easy navigation and
                visibility of payments processed. Should the User wish to access
                full reporting on payments and further payment information, the
                User should log into their Our partners Merchant Account
                directly.
              </li>
              <li>
                Each transaction will be settled directly into the User's
                business bank Account after deducting any Merchant Fees and any
                Platform Fees. Kleo does not have access to the funds in the
                User's business bank account.
              </li>
              <li>
                The User may choose to refund a transaction(s) after processing
                the payment. Regardless of whether a payment is refunded, the
                User is still required to pay the full Merchant Fees for the
                refunded transactions. Merchant fees are non-refundable in any
                circumstances.
              </li>
              <li>
                The User may be required to pass any Anti-Money Laundering
                checks at any time, irrespective of which payment service
                provider they choose. The User shall always comply with all
                Anti-Money Laundering statute and legislation. Any
                non-compliance shall constitute a breach of this Agreement and
                the possibility for immediate termination of this Agreement as
                per Section 31.4.6.
              </li>
              <li>
                Payment Processing can only be activated when the User enters
                their login credentials (merchant key) onto the POS terminal
                and/or Kleo Admin control panel.
              </li>
              <li>
                Kleo is not responsible for any issues with the payment gateway,
                including, but not limited to, fraudulent transactions and bank
                chargebacks. The Kleo Technical Support team can only assist
                with first line technical support and login issues, and any
                unresolved issues shall be passed on to Our partners. Any
                payment processing issues, including, but not limited to,
                fraudulent transactions and bank chargebacks shall be addressed
                to Our partners directly.
              </li>
              <li>
                It is the User's responsibility to familiarise themselves with
                all Our partners processes and fees associated with disputes and
                fraud, as well as best practises and guidance around identifying
                potential fraud.
                <ol>
                  <li>
                    A dispute (also known as a chargeback, inquiry, or
                    retrieval) occurs when a cardholder questions a payment with
                    their card issuer. An inquiry or retrieval is a request for
                    more information about the charge, which may escalate to a
                    chargeback. To avoid an inquiry or retrieval becoming a
                    chargeback, submit any required evidence or refund the
                    payment in full. Refunding the payment marks the inquiry or
                    retrieval as resolved and does not incur a dispute fee.
                  </li>
                  <li>
                    To process a chargeback, the card issuer creates a formal
                    dispute, which immediately reverses the payment. The payment
                    amount, along with a separate £15.00 dispute fee, is then
                    deducted from the Merchant's account balance. The dispute
                    fee amount is subject to change as per Our partners's
                    discretion. Kleo is not responsible for communicating any
                    changes to the dispute fee amount to the User.
                  </li>
                  <li>
                    The User has full responsibility and/or liability for all
                    payment disputes. The User shall deal with payment disputes
                    through their Payment Dashboard by providing necessary
                    evidence. In most cases, Our partners does not decide the
                    outcome of the dispute. Our partners conveys your evidence
                    to their financial partners. Our partners's financial
                    partners then pass your evidence to the card issuers, if
                    they deem it sufficient. If you do not submit evidence by
                    the time the deadline passes, the cardholder wins the
                    dispute and keeps the funds.
                  </li>
                  <li>
                    All costs incurred during the dispute resolution process
                    shall be covered by the User. The User shall keep the
                    Company indemnified against any payment disputes and costs
                    incurred during the dispute settlement process. The User
                    acknowledges and agrees that using any form of Payment
                    Processing is at the User's own risk.
                  </li>
                </ol>
              </li>
              <li>
                In the event that Kleo's partnership with Our partners is
                terminated, the User shall need to switch to another integrated
                provider to continue using Kleo Payments for online ordering,
                and/or contactless table ordering, and/or self-checkout
                ordering, etc. The Company shall inform the User in advance of
                any such termination in partnership and provide details of any
                new third-party payment service provider that will replace Our
                partners's functionalities. The User shall have the opportunity
                to review any new terms and conditions deriving from the new
                partnership and choose to switch to the new payment service
                provider, or a different third-party payment service provider
                (please see Section 15), or cancel any Kleo services altogether
                (please see Section 23). The Company shall endeavour to make the
                transition period and process as smooth as possible for the User
                and their customers.
              </li>
            </ol>
          </li>
          <li>
            <h4>
              Kleo Payments with Other Third-Party Payment Service Providers
            </h4>
            <ol>
              <li>
                The Company's Software supports Payment Processing with certain
                third-party partners (payment service providers) approved by
                Kleo. It should be noted that Kleo is not a Payment Service
                Provider and is not regulated by FCA as a payment service
                provider, rather merely an integrator of payment processing
                tools. The User acknowledges and agrees that using any form of
                Payment Processing is at the User's own risk.
              </li>
              <li>
                Should the User wish to use online ordering, and/or contactless
                table ordering, and/or self-checkout ordering systems provided
                by Kleo, the User must choose one payment service provider from
                the list of Kleo approved partners. The User should take into
                consideration the cost, flexibility, and speed offered by each
                payment service provider before deciding which payment service
                provider will be best suited to the User's business. Unless
                otherwise stated in a quotation, or any other documents in the
                Appendix of this Agreement, the Company shall not be guiding the
                User in the selection process. The User can request the Company
                to provide a list of all integrated payment service providers at
                any time.
              </li>
              <li>
                Company payment processing partners may change from time to time
                or withdraw integration with or without prior notice. In such
                cases, the customer shall need to switch to another integrated
                provider to continue using Kleo Payments for online ordering,
                and/or contactless table ordering, and/or self-checkout
                ordering, etc.
              </li>
              <li>
                Payment Processing can only be activated when the User enters
                their login credentials (merchant key) onto the POS terminal.
              </li>
              <li>
                Kleo is not responsible for any issues with the payment gateway,
                including, but not limited to, fraudulent transactions and bank
                chargebacks. The Kleo Technical Support team cannot assist with
                any payment processing issues, including, but not limited to,
                fraudulent transactions, bank chargebacks and login issues. The
                User shall direct such issues to their bank/merchant account
                provider.
              </li>
              <li>
                The User may be required to pass any Anti-Money Laundering
                checks at any time, irrespective of which payment service
                provider they choose. The User shall always comply with all
                Anti-Money Laundering statute and legislation. Any
                non-compliance shall constitute a breach of this Agreement and
                the possibility for immediate termination of this Agreement as
                per Section 31.4.6.
              </li>
              <li>
                Other than as specified in Section 15.2, should the User work
                with a Payment Service Provider not integrated into the
                Company's ePOS Software, the User shall need to manually enter
                all payment details onto the merchant card terminal.
              </li>
              <li>
                This Section 15 is only applicable to Users who have opted for
                Kleo Payments processed by a third-party partner other than Our
                partners.
              </li>
            </ol>
          </li>
          <li>
            <h4>Quote Validity</h4>
            <ol>
              <li>
                The quote provided by the Company is valid for a period of
                duration, of 7 days from the date of issuance.
              </li>
            </ol>
          </li>
          <li>
            <h4>Credit Broking Service</h4>
            <ol>
              <li>
                The Company works with third-party companies, which provide
                finance products with the aim to assist our users when
                purchasing hardware goods from us.
              </li>
              <li>
                The User acknowledges and consents to third-party financial
                institutions' requirement to access their credit score
                information. This access is necessary to evaluate the User's
                eligibility for obtaining financial assistance and to gauge the
                associated risk for the third-party institution during the
                process of providing such assistance. In each instance, a credit
                check may be recorded on the User's credit score profile. Kleo
                has no impact over the third-party companies' decision whether
                to provide financial assistance to any user.
              </li>
              <li>
                Each hardware purchase requiring financial assistance shall be
                provided in the form of a proposal by the third-party company
                and be governed by separate terms and conditions between the
                User and the third-party company.
              </li>
              <li>
                It is the User's responsibility to evaluate the impact of any
                fees, loan terms and interest rates on the overall repayment sum
                and their capacity to afford the scheduled repayments. For
                instance, shorter terms typically come with higher monthly
                payments but lower overall interest costs; while longer terms
                may have lower monthly payments but can result in higher total
                interest payments over time; or you may be charged an
                early-repayment fee by your lender for repaying the full amount
                sooner than agreed.
              </li>
              <li>
                All repayments shall be made directly to the third-party company
                providing the User with finance assistance. Any delayed or
                missed payments may affect your credit score and/or incur a
                penalty fee. Kleo has no involvement in the repayment process
                and shall not be responsible for transferring any payments
                wrongly sent by the User to Kleo, rather than their lender.
              </li>
              <li>
                You may be able to independently obtain finance with better
                terms. Kleo makes no warranties or representations that the
                portfolio of third-party lenders we work with may be able to
                provide the best interest rates or payment terms available to
                you.
              </li>
              <li>
                Any complaints regarding the third-party company providing your
                finance shall be raised with them directly. You should
                familiarise yourself with their complaints procedure. Kleo has
                no involvement in the services, policies and procedures of any
                third-party lenders we may work with.
              </li>
              <li>
                Kleo may receive commission from third-party lenders for
                referring their services to our users. The Company shall not be
                required to disclose any further information about any such
                commissions.
              </li>
              <li>
                Any termination of this Agreement does not constitute
                termination of the agreement you may still have with a
                third-party lender. You are liable for the full repayment of
                your financed sum, including any interest and fees associated
                with that agreement.
              </li>
            </ol>
          </li>
          <li>
            <h4>Customer Support Services</h4>
            <ol>
              <li>
                All queries, requests and/or issues shall be directed to the
                Company Customer Support Team for review. The Customer Support
                Team, which includes the Technical Support Team, is operational
                on a 24/7 basis. In the event of any unforeseen disruption to
                the telephone and/or Internet connection with the Customer
                Support Team, the operation times may be reduced. The Company
                shall endeavour to minimise the effect of any such disruption
                onto the Customer Support Team operations.
              </li>
              <li>
                The Company uses an internal ticketing system across all support
                channels. All data collected via the ticketing system shall only
                be used for ticket prioritisation and producing reports for
                internal management and improvements. Any data used for internal
                reports shall be anonymised first. No data shall be distributed
                to any third party without prior consent from the User.
              </li>
              <li>
                The customer support channels available to the User shall be
                dependent upon the User's subscription level. In the unlikely
                event that a particular support channel experiences technical
                difficulties and becomes temporarily unavailable, the User shall
                attempt to contact the Customer Support Team via a different
                support channel.
              </li>
              <li>
                The User shall receive technical support in case of any issues
                with the Company's software and or hardware within 24 hours of
                sending a request through telephone or email at
                support@kleocloud.com. For more complex issues, the request will
                be passed to our second line support team for resolution within
                72 hours of the initial User's request. In the unlikely event
                that the Technical Support Team encounters a new issue not dealt
                with before, the Company shall endeavour to keep the User
                informed on the expected timeframe for resolution and keep any
                disruption to the Services functionality to a minimum.
              </li>
              <li>
                Telephone technical support (+44 7417512578) is available to the
                User free of charge for the first three (3) months of their
                license subscription. Should the User require telephone
                technical support for longer than the first three (3) months of
                their license subscription, this shall be charged as an
                additional cost to the User's monthly/annual subscription OR
                shall be included in a quotation.
              </li>
              <li>
                Remote training on the use of the Cloud services can be provided
                upon request by the User, depending on reasonable timeframes and
                availability of Company engineers. Repeat training may be
                charged at an additional cost to the User. Any such cost and
                payment process shall be communicated to the User in advance.
              </li>
              <li>
                The Company does not offer on-site visits to the User's
                locations as part of our technical support services. All
                technical support provided by the Company is on a remote basis
                only.
                <ol>
                  <li>
                    The User shall be required to assist with troubleshooting
                    any technical issues while being guided by an engineer
                    remotely.
                  </li>
                  <li>
                    Should any troubleshooting require additional time for
                    resolving, the User shall be given the option to proceed
                    with the troubleshooting at a time more convenient to the
                    User.
                  </li>
                  <li>
                    Should the User be required to provide remote access to
                    their device(s) and/or screen share with a technical support
                    engineer, the User shall be responsible for ensuring all
                    sensitive data is closed on their device before remote
                    access and/or screen share begins.
                  </li>
                </ol>
              </li>
              <li>
                The Company Customer Support Team is currently based in India.
                This location is subject to change based on the Company's sole
                discretion. Kleo shall review the Customer Support Team's
                location as deemed necessary by the Company. The Company shall
                not be required to obtain the User's consent should the Company
                decide to move the Customer Support Team to a new location.
              </li>
            </ol>
          </li>
          <li>
            <h4>Third-Party Licensing, Tools, and Integrations</h4>
            <ol>
              <li>
                The Software is developed in Android, PHP, Python, Flutters,
                Angular, HTML, MySQL, JavaScript, eJabber, CSS and a number of
                other software tools. These are currently license-free,
                therefore, no software license is required for the users. If any
                third-party software used becomes paid, the User shall subscribe
                to it or pay additional fees to the Company to cover the
                necessary license fees.
              </li>
              <li>
                The Company has integrated Google Maps API to find addresses
                with a postcode. These address lookups are useful while taking
                orders, registering customers, loyalty management, etc. The
                Company has no control over these records, therefore it does not
                hold any responsibility or liability for the accuracy of such
                records. The Company shall provide the User with one thousand
                (1,000) address lookups free of charge every month. Any
                additional address lookups shall be charged at an additional
                rate, as specified in the subscription level or Quotation, and
                added onto the following month's subscription charges.
              </li>
              <li>
                To enhance the Software usage, the company has integrated
                third-party tools such as, but not limited to, Deliveroo,
                Deliverect, MailChimp, Message Bird, SendGrid, Twilio, Xero and
                many others. If the User wants to use the services from any
                integrated third-party tools, they must subscribe to services
                directly from the provider. The Company does not hold any
                responsibility for pricing, tool-performance and disputes
                related to third-party tools. Should the User already have a
                subscription with any of the integrated third-party tools, the
                User may sync their third part account with the Software.
              </li>
              <li>
                Third-party tools and/or integrations may change with or without
                prior notice to Kleo and/or the User. The Company shall not be
                held liable by the User for any loss of profits, business,
                goodwill, use, data or other intangible and tangible losses
                resulting from the withdrawal of any third-party tools and/or
                integrations with or without prior notice.
              </li>
              <li>
                The User can request the Company to provide a list of all
                third-party tools and integrations at any time.
              </li>
            </ol>
          </li>
          <li>
            <h4>Hardware Refunds Policy</h4>
            <ol>
              <li>
                Upon submitting your order details, you are making an offer to
                us to purchase the item(s) you have specified in your order
                form. We reserve the right to refuse your order should it be
                necessary. We will notify you if this is the case and return any
                payment that you have made.
              </li>
              <li>
                You must notify us immediately if you decide to cancel your
                order, preferably by phone, and quote your order number.
                <ol>
                  <li>
                    Once direct debit is set up or if the payment is made, the
                    order will be dispatched for delivery. Your delivery
                    cancellation request will be refused, and you will need to
                    refer to the process detailed in Section 19.6.
                  </li>
                  <li>
                    If the cancellation was made before on-boarding is complete
                    and the direct debit is set-up or payment is made, and once
                    we have accepted your delivery cancellation request, we will
                    cancel the full order and you will not be liable for the
                    contract.
                  </li>
                </ol>
              </li>
              <li>
                If we can only do a split delivery (not all items from the order
                are available at the same time), our staff shall inform you of
                the expected date for delivery of the remaining items. You have
                the right to refuse a split delivery before the order is
                dispatched and wait for all items to become available at the
                same time, or request to cancel your order and get a refund.
              </li>
              <li>
                In the unlikely event that we deliver a wrong item of hardware,
                you have the right to either:
                <ol>
                  <li>
                    Reject the delivery of the wrong item and you shall be fully
                    refunded for the missing item;
                  </li>
                  <li>
                    Reject the delivery of the wrong item and request for us to
                    replace the incorrect item with the item that you ordered,
                    in which case, we shall send you the correct one as soon as
                    possible.
                  </li>
                  <li>
                    If you discover that a wrong item has been dispatched after
                    the delivery has been completed, you will be required to
                    return the incorrect item to avoid being charged for it.
                  </li>
                </ol>
              </li>
              <li>
                Should any items arrive damaged in transit, the User needs to
                report this to Kleo within seven (7) working days of delivery.
                If the packaging is visibly damaged when received, the User is
                required to bring this to the attention of the delivery driver
                who may be able to record this on their system. Should any
                damaged items need to be replaced, the User shall make them
                available for collection with original packaging including all
                accessories and documentation. Kleo shall arrange the collection
                and process a replacement delivery.
              </li>
              <li>
                Under the definitions given in the Consumer Rights Act 2015, and
                the terms of Section 1.6, the User is not classed as a
                “consumer” and no consumer statutory rights are applicable to
                this Agreement. However, as a gesture of goodwill, Kleo operates
                a “7 Days Money Back Guarantee” policy for purchases of Hardware
                items.
                <ol>
                  <li>
                    Should you change your mind and wish to return your Hardware
                    order due to faults in the hardware, the Company shall issue
                    a full refund for any undamaged and unused items returned
                    within seven (7) days of delivery, minus any delivery
                    charges for delivery and/or returns. The item(s) returned
                    must be unused, still in their original packaging, including
                    all accessories and documentation, and must be in “as new”
                    and resalable condition.
                  </li>
                  <li>
                    The Company reserves the right to refuse a full or partial
                    refund if:
                    <ul>
                      <li>
                        any item is not returned in its original packaging; or
                      </li>
                      <li>
                        any item is missing any accessories and/or
                        documentation; or
                      </li>
                      <li>
                        the goods are in a damaged condition (any damaged
                        equipment will be charged at full cost); or
                      </li>
                      <li>
                        the goods are not returned within seven (7) days of
                        delivery.
                      </li>
                    </ul>
                  </li>
                  <li>
                    19.6.3. Should you wish to return your goods to us, you will
                    be responsible for the delivery costs associated with the
                    return. Kleo can organise a collection on your behalf, but
                    the associated costs will be deducted from your total refund
                    amount.
                  </li>
                </ol>
              </li>
              <li>
                For any other formal complaint with a genuine reason, you have
                seven (7) days from the day of delivery to request a refund,
                subject to our discretion and acceptance of the complaint.
              </li>
              <li>
                In the event of a return, any relevant refunds, credit notes or
                exchanges can only be made to the payment card account or the
                business/person that made the order originally. Debit/credit
                card payment refunds can take up to twenty-eight (28) days to
                process, even if you contact us immediately after placing your
                order to cancel. This processing time is beyond our control and
                we apologise for the inconvenience it may cause.
              </li>
              <li>
                Any amounts paid for onsite installations, product imports,
                support payments, postage, module integrations, repair and
                labour costs are non-refundable.
              </li>
              <li>
                All costs with respect to Software sales and license
                subscriptions are excluded from the standard returns policy due
                to the considerable costs in configuration, personalization,
                training, and installation. These items are strictly
                non-refundable.
              </li>
            </ol>
          </li>
          <li>
            <h4>Hardware Warranty Returns Process</h4>
            <ol>
              <li>
                The User reserves the right to request a warranty return, by
                contacting the Customer Support Team, should the User feel the
                equipment provided by Kleo is ‘not fit for purpose' within the
                one (1) year basic manufacturer's warranty.
              </li>
              <li>
                The Company shall endeavour to obtain proper reasons as to why
                the User feels the product is ‘not fit for purpose' and attempt
                to reasonably resolve any issues upon remote troubleshooting.
              </li>
              <li>
                Should the issues not be resolved remotely, the Company shall
                request for the User to send, via a courier service, the faulty
                hardware to a nominated address for repair. The User shall pack
                the hardware in appropriate packaging to avoid any damage in
                transit. The Company reserves the right to charge the User for
                any damage caused in transit due to poor packaging.
              </li>
              <li>
                Once the hardware is repaired, the Company shall post the
                repaired hardware back to the User. Should the fault be
                non-repairable, the Company shall send the User a replacement of
                the faulty hardware. Any replacement hardware may be a
                refurbished item.
              </li>
              <li>
                If the item returned is found to be in a working condition but
                in a poor and unreasonable state of cleanliness, the User will
                be billed for cleaning at a fixed rate of £50 per item.
              </li>
              <li>
                Warranty refunds may only be offered by the Company after all
                endeavours have been made by the Company to resolve any arising
                issue/fault and no reasonable replacement equipment is
                available.
              </li>
              <li>
                The company cannot process a warranty refund of hardware due to
                any of the following reasons:
                <ol>
                  <li>
                    The User or staff not understanding or using the system
                    properly;
                  </li>
                  <li>Lack of compatibility with third-party items/systems;</li>
                  <li>
                    The equipment has been configured, personalized and/or used;
                  </li>
                  <li>
                    Operational problems arising from the User's unstable or
                    insufficient internet connection;
                  </li>
                  <li>
                    The User has accidentally damaged the item after delivery;
                  </li>
                  <li>The User has misused the item and damaged it;</li>
                  <li>
                    The User has tried to open or tamper with the hardware in
                    any way;
                  </li>
                  <li>
                    Any environmental problems that are out of the Company's
                    control;
                  </li>
                  <li>The User's business goes bankrupt.</li>
                </ol>
              </li>
              <li>
                The Company does not offer on-site visits to the User's location
                as part of our warranty repair process. All technical support
                provided by the Company is on a remote basis.
              </li>
              <li>
                Hardware repairs outside the one (1) year basic manufacturer's
                warranty could be carried out at an additional cost to the User.
                Any such cost shall be agreed by the Company and the User, prior
                to any work being carried out.
              </li>
            </ol>
          </li>
          <li>
            <h4>SMS/MMS Mobile Message Marketing Program</h4>
            <ol>
              <li>
                User Opt In: The Program allows Users to receive SMS/MMS mobile
                messages by affirmatively opting into the Program, such as
                through online, offline, or application-based enrolment forms.
                Regardless of the opt-in method the User has utilized to join
                the Program, the User acknowledges that this Agreement applies
                to the User's participation in the Program.
                <ul>
                  <li>
                    By participating in the Program, the User agrees to receive
                    autodialled or manually dialled marketing mobile messages
                    (as applicable) at the phone number associated with the
                    User's opt-in, and the User understands that consent is not
                    required to make any purchase from the Company.
                  </li>
                  <li>
                    While the User consents to receive messages sent using an
                    autodialer, the foregoing shall not be interpreted to
                    suggest or imply that any or all of the Company's mobile
                    messages are sent using an automatic telephone dialling
                    system (“ATDS” or “autodialer”). Message and data rates may
                    apply.
                  </li>
                </ul>
              </li>
              <li>
                User Opt Out: The User reserves the right to request to opt out
                of the Program, should the User not wish to continue
                participating in the Program.
                <ul>
                  <li>
                    In order to opt out of the Program, the User must reply
                    “STOP”, “END”, “CANCEL”, “UNSUBSCRIBE”, or “QUIT” to any
                    mobile message from the Company. The User may receive an
                    additional mobile message confirming the User's decision to
                    opt out.
                  </li>
                  <li>
                    The User understands and agrees that the foregoing options
                    are the only reasonable methods of opting out.
                  </li>
                  <li>
                    The User understands and agrees that any other method of
                    opting out, including, but not limited to, texting words
                    other than those set forth above or verbally requesting one
                    of the Company's employees to remove the User from the
                    Program's mailing list, is not a reasonable means of opting
                    out.
                  </li>
                </ul>
              </li>
              <li>
                Duty to Notify and Indemnify: If at any time the User intends to
                stop using the mobile telephone number that has been used to
                subscribe to the Program, including cancelling the User's
                service plan or selling or transferring the telephone number to
                another party, the User must complete the User Opt Out process
                set forth above (Section 21.3) prior to ending the User's use of
                the mobile telephone number.
                <ul>
                  <li>
                    The User understands and agrees that the User's agreement to
                    do so is a material part of these terms and conditions.
                  </li>
                  <li>
                    The User agrees that the User shall indemnify, defend, and
                    hold the Company harmless from any claim or liability
                    resulting from the User's failure to notify us of a change
                    in the information the User has provided the Company. The
                    User understands and agrees that, if the User discontinues
                    the use of the User's mobile telephone number without
                    notifying Kleo of such change, the User will be responsible
                    for all costs (including any legal fees) and liabilities
                    incurred by the Company, or any party that assists in the
                    delivery of the mobile messages, as a result of claims
                    brought by individual(s) who are later assigned that mobile
                    telephone number.
                  </li>
                  <li>
                    This duty and agreement shall survive any cancellation or
                    termination of the User's agreement to participate in any of
                    the Company's Programs.
                  </li>
                </ul>
              </li>
              <li>
                Program Description: Without limiting the scope of the Program,
                users who opt in to the Program can expect to receive messages
                concerning the marketing and sale of digital and physical
                products, services, and events.
              </li>
              <li>
                Cost and Frequency: Message and data rates may apply. The
                Program involves recurring mobile messages, and additional
                mobile messages may be sent periodically based on your
                interaction with Kleo.
              </li>
              <li>
                Support Instructions: For support regarding the Program, text
                “HELP” to the number you received messages from or email Kleo at
                info@Kleo.com. Please note that the use of this email address is
                not an acceptable method of opting out of the program. Opt out
                requests must be submitted in accordance with the procedures set
                forth above (Section 21.3).
              </li>
              <li>
                MMS Disclosure: The Program will send SMS TMs (terminating
                messages) if your mobile device does not support MMS messaging.
              </li>
              <li>
                Disclaimer of Warranty: The Program is offered on an "as-is"
                basis and may not be available in all areas at all times and may
                not continue to work in the event of product, software, coverage
                or other changes made by the User's wireless carrier/service
                provider/network operator.
                <ul>
                  <li>
                    The Company shall not be liable for any delays or failures
                    in the receipt of any mobile messages connected with this
                    Program. Delivery of mobile messages is subject to effective
                    transmission from your wireless service provider/network
                    operator and is outside of the Company's control.
                  </li>
                  <li>
                    Kleo, any party that assists the Company in the delivery of
                    the mobile messages, and the respective wireless carriers,
                    including T-Mobile, are not liable for delayed or
                    undelivered mobile messages.
                  </li>
                </ul>
              </li>
              <li>
                Participant Requirements: the User must have a wireless device
                of their own, capable of two-way messaging, be using a
                participating wireless carrier, and be a wireless service
                subscriber with text messaging service. Not all cellular
                telephone providers carry the necessary service to participate
                in the Program. The User should check their telephone
                capabilities for specific text messaging instructions.
              </li>
              <li>
                Age Restriction: You may not use or engage with the Program if
                you are under thirteen (13) years of age. If you use or engage
                with the Program and are between the ages of thirteen (13) and
                eighteen (18) years of age, you must have your parent's or legal
                guardian's permission to do so. By using or engaging with the
                Program, you acknowledge and agree that you are not under the
                age of thirteen (13) years; are between the ages of thirteen
                (13) and eighteen (18) and have your parent's or legal
                guardian's permission to use or engage with the Program; or are
                of adult age in your jurisdiction. By using or engaging with the
                Program, you also acknowledge and agree that you are permitted
                by your jurisdiction's applicable laws to use and/or engage with
                the Platform.
              </li>
              <li>
                Prohibited Content: the User acknowledges and agrees to not send
                any prohibited content over the Program. Prohibited content
                includes, but is not limited to:
                <ul>
                  <li>
                    Any fraudulent, libellous, defamatory, scandalous,
                    threatening, harassing, or stalking activity;
                  </li>
                  <li>
                    Objectionable content, including profanity, obscenity,
                    lasciviousness, violence, bigotry, hatred, and
                    discrimination on the basis of race, sex, religion,
                    nationality, disability, sexual orientation, or age;
                  </li>
                  <li>
                    Pirated computer programs, viruses, worms, Trojan horses, or
                    other harmful code;
                  </li>
                  <li>
                    Any product, service, or promotion that is unlawful where
                    such product, service, or promotion thereof is received;
                  </li>
                  <li>
                    Any content that implicates and/or references personal
                    information that is protected by the Data Protection Act
                    2018; and
                  </li>
                  <li>
                    Any other content that is prohibited by the applicable laws
                    in the jurisdiction from which the message is sent.
                  </li>
                </ul>
              </li>
              <li>
                Dispute Resolution, Arbitration and Class Action Waiver. Please
                read this Section carefully. It affects your rights and will
                have a substantial impact on how claims between the Parties are
                resolved:
                <ul>
                  <li>
                    In the event that there is a dispute, claim, or controversy
                    between the Parties, or any other third-party service
                    provider, including Autopilot, acting on the Company's
                    behalf to transmit the mobile messages within the scope of
                    the Program, arising out of or relating to federal or state
                    statutory claims, common law claims, this Agreement, or the
                    breach, termination, enforcement, interpretation or validity
                    thereof, including the determination of the scope or
                    applicability of this agreement to arbitrate, such dispute,
                    claim, or controversy will be, to the fullest extent
                    permitted by law, determined by arbitration in 38 West
                    Gorgie Parks, Edinburgh, EH5 1RT, before one sole
                    arbitrator.
                  </li>
                  <li>
                    The Parties agree to submit the dispute to binding
                    arbitration in accordance with the Arbitration Act 1996.
                    Except as otherwise provided herein, the arbitrator shall
                    apply the applicable laws (Section 32) of the jurisdiction
                    in which Kleo's principal place of business is located,
                    without regard to the possible conflict of laws between
                    different jurisdictions in which the Parties may be
                    operating.
                  </li>

                  <li>
                    Within ten (10) calendar days after the arbitration demand
                    is served upon a party, the Parties must jointly select an
                    arbitrator with at least five (5) years' experience in that
                    capacity and who has knowledge of and experience with the
                    subject matter of the dispute.
                  </li>
                  <li>
                    If the Parties do not agree on an arbitrator within ten (10)
                    calendar days, a Party may apply for the Law Society
                    president to appoint an arbitrator, who must satisfy the
                    same experience requirement.
                  </li>
                  <li>
                    In the event of a dispute, the arbitrator shall decide the
                    enforceability and interpretation of this arbitration
                    agreement in accordance with the Arbitration Act 1996.
                  </li>
                  <li>
                    The Parties agree that the Party submitting the dispute for
                    arbitration, may request an emergency action by the other
                    Party, in lieu of seeking emergency injunction from a court,
                    with the same effect as a court injunction. The Parties
                    agree that the appointment of an emergency arbitrator would
                    not be necessary due to the additional costs of doing so.
                  </li>
                  <li>
                    The decision of the arbitrator shall be final and binding,
                    and no party shall have rights of appeal except for those
                    provided in Section 68 of the Arbitration Act 1996.
                  </li>
                  <li>
                    Each Party shall bear its share of the fees paid for the
                    arbitrator and the administration of the arbitration;
                    however, the arbitrator shall have the power to order one
                    party to pay all or any portion of such fees as part of a
                    well-reasoned decision. The Parties agree that the
                    arbitrator shall have the authority to award arbitration
                    fees only to the extent expressly authorised by statute or
                    contract.
                  </li>
                  <li>
                    The arbitrator shall have no authority to award punitive
                    damages and each party hereby waives any right to seek or
                    recover punitive damages with respect to any dispute
                    resolved by arbitration.
                  </li>
                  <li>
                    The Parties agree to arbitrate solely on an individual
                    basis, and this Agreement does not permit class arbitration
                    or any claims brought as a claimant or class member in any
                    class or representative arbitration proceeding.
                  </li>
                  <li>
                    Except as may be required by law, neither a party nor the
                    arbitrator may disclose the existence, content, or results
                    of any arbitration without the prior written consent of both
                    Parties, unless to protect or pursue a legal right.
                  </li>
                  <li>
                    If any term or provision of this Section is invalid,
                    illegal, or unenforceable in the jurisdiction specified in
                    Section 32, such invalidity, illegality, or unenforceability
                    shall not affect any other term or provision of this
                    Section.
                  </li>
                  <li>
                    If for any reason a dispute proceeds in court rather than in
                    arbitration, the Parties hereby waive any right to a jury
                    trial. This arbitration provision shall survive any
                    cancellation or termination of the User's agreement to
                    participate in any of the Company's Programs.
                  </li>
                </ul>
              </li>
              <li>
                The User warrants and represents to the Company that the User
                has all necessary rights, power, and authority to agree to these
                Terms and perform the User's obligations hereunder, and nothing
                contained in this Agreement or in the performance of such
                obligations will place the User in breach of any other contract
                or obligation.
              </li>
              <li>
                The failure of either Party to exercise in any respect any right
                provided for herein will not be deemed a waiver of any further
                rights hereunder.
              </li>
              <li>
                If any provision of this Agreement is found to be unenforceable
                or invalid, that provision will be limited or eliminated to the
                minimum extent necessary so that this Agreement will otherwise
                remain in full force and effect and enforceable.
              </li>
              <li>
                Any new features, changes, updates or improvements of the
                Program shall be subject to this Agreement unless explicitly
                stated otherwise in writing.
              </li>
              <li>
                Kleo reserves the right to change this Agreement from time to
                time. Any updates to this Agreement shall be communicated to the
                User. The User acknowledges the User's responsibility to review
                this Agreement from time to time and to be aware of any such
                changes. By continuing to participate in the Program after any
                such changes, the User accepts this Agreement, as modified.
              </li>
            </ol>
          </li>
          <li>
            <h4>Formal Complaints Procedure</h4>
            <ol>
              <li>
                Kleo is committed to providing a quality service for its
                customers and operating in an open, accountable, and respectful
                way. If you are unsatisfied with any part of our service, please
                contact us immediately via telephone or email. The Company shall
                endeavour to resolve any issues within reasonable time. The
                Company shall use the following Complaints Procedure with
                regards to any issues not related to hardware malfunctioning:
                <ol>
                  <li>
                    Stage 1 - If you are unable to resolve an issue informally,
                    you should contact us directly in writing via email or post
                    and give us a chance to put things right. In your contact
                    email/letter to us, you should set out the details of your
                    complaint, the resulting consequences you have faced, and
                    the remedy that you seek. You can expect your complaint to
                    be acknowledged within five (5) business days of receipt and
                    you should get an official response within twenty (20)
                    business days. Our contact details can be found on the
                    ‘Contact Us' part of our Kleo Website -{" "}
                    <a href="https://www.Kleo.com/uk/contact-us">
                      https://www.Kleo.com/uk/contact-us
                    </a>
                  </li>
                  <li>
                    Stage 2 - If you are not satisfied with the initial response
                    to the complaint, then you can write to the Chief Executive
                    Officer (CEO) via email or post and ask for a review of your
                    complaint and response received. You can expect the CEO to
                    acknowledge your request within five (5) business days of
                    receipt and a response within twenty (20) business days.
                    Kleo aims to resolve every issue as quickly as reasonably
                    possible. However, certain issues may be more complex and,
                    therefore, may require more time for a thorough
                    investigation. If an issue requires further investigation,
                    you will receive details of what steps are being taken to
                    investigate the complaint, in what timescales an official
                    response can be expected and from whom.
                  </li>
                  <li>
                    Final Stage - If you are not satisfied with the subsequent
                    reply and action taken by the CEO, then you have the option
                    of writing to any of the following, depending on the nature
                    of your complaint:
                    <ul>
                      <li>
                        Information Commissioner's Office:{" "}
                        <a href="https://ico.org.uk/">https://ico.org.uk/</a>
                      </li>
                      <li>
                        Health and Safety Executive:{" "}
                        <a href="http://www.hse.gov.uk/">
                          http://www.hse.gov.uk/
                        </a>
                      </li>
                      <li>
                        Citizens Advice
                        <br />
                        Telephone: 0808 223 1133
                        <br />
                        Website:{" "}
                        <a href="https://www.citizensadvice.org.uk/consumer/get-more-help/if-you-need-more-help-about-a-consumer-issue/">
                          https://www.citizensadvice.org.uk/consumer/get-more-help/if-you-need-more-help-about-a-consumer-issue/
                        </a>
                      </li>
                      <li>
                        The Financial Ombudsman Service
                        <br />
                        Exchange Tower
                        <br />
                        London E14 9SR
                        <br />
                        Telephone: 0800 023 4567
                        <br />
                        Website:{" "}
                        <a href="https://www.financial-ombudsman.org.uk/">
                          https://www.financial-ombudsman.org.uk/
                        </a>
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <h4> Cancellation of License Subscription</h4>
            <ol>
              <li>
                The User may request to cancel their renewal of license
                subscription subject to a thirty (30) days' notice to Kleo by
                contacting the Customer Support Team via email or telephone and
                provide explicit written notice of cancellation to{" "}
                <a href="mailto:support@kleocloud.com">support@kleocloud.com</a>
              </li>
              <li>
                In the event the Customer chooses to terminate the subscription
                before the completion of the agreed two-year term, the Customer
                shall be liable for the remainder of the subscription period and
                agrees to pay the outstanding amount for the remaining contract
                term.
              </li>
              <li>
                The outstanding amount shall be calculated as the prorated fees
                for the remaining months of the contract term
              </li>
              <li>
                The User reserves the right to withdraw their cancellation
                request within the thirty (30) days' notice period and continue
                the license subscription without any obstructions.
              </li>
            </ol>
          </li>

          <li>
            <h4>Tax Calculations</h4>
            <ol>
              <li>
                Kleo calculates tax on each line which is sold in a transaction
                as a common practice in most software.
              </li>
              <li>
                The User might see a difference in total tax when calculating
                tax based on single transaction vs total sales for a specific
                period. For example, if you consider an item sold at 25p with
                20% tax, this will result in a tax of 4.16p which is rounded to
                4p per transaction. If this item is sold separately 100 times,
                then the accrued tax will be £4.00. However, if you calculate
                this as 20% of the total 100 items, you will get £4.16 tax.
              </li>
              <li>
                Discounts applied to tax on a transaction are taken equally from
                all items in the transaction (pro-rota).
              </li>
              <li>
                Kleo is not liable for any incorrect tax calculations. It is the
                User's responsibility to take professional tax advice before
                submitting returns to tax and other governing authorities.
              </li>
            </ol>
          </li>
          <li>
            <h4>Company Income from Third-Party Referrals</h4>
            <ol>
              <li>
                The User acknowledges and accepts that the Company will be
                receiving financial incentives/commission from third-party
                providers such as leasing, finance, card processing, software
                tools, delivery partners, hardware tools and other providers for
                referring the User.
              </li>
              <li>
                The Company shall not be notifying or disclosing to the User any
                such financial incentives/commission figures at any time.
              </li>
              <li>
                The User acknowledges and accepts that the Company reserves full
                rights on receiving and retaining such financial incentives.
              </li>
            </ol>
          </li>
          <li>
            <h4>Intellectual Property Rights</h4>
            <ol>
              <li>
                The User acknowledges and agrees that iHybrid will hold all
                intellectual property rights in the software and hardware
                including, but not limited to, copyright and trademark rights.
              </li>
              <li>
                Under the terms of this license, the Company grants the User
                non-exclusive, non-transferable, and time-limited rights to use
                the software, interfaces, content, documentation, and any data,
                whether in read-only memory, on any other media or in any other
                form.
              </li>
              <li>
                The User undertakes not to challenge or do anything inconsistent
                with such ownership.
              </li>
              <li>
                The User grants the Company, by using the software, a
                royalty-free, irrevocable, perpetual license to use information
                and/or data collected from the User's activity on the Software,
                provided that the information or data is anonymised before use.
                Other than this right, the Company claims no intellectual
                property rights in relation to the information or contents the
                User inputs into the Software.
              </li>
              <li>
                The User may provide the Company with feedback, alternative
                options, comments or suggestions on the Software, and the User
                agrees that the Company will be free to use, modify and
                incorporate such suggestions without any obligation to the User.
                All intellectual property rights for such modifications remain
                with Kleo.
              </li>
              <li>
                The Company retains ownership of the software itself and
                reserves all rights not expressly granted to the User.
              </li>
              <li>
                The User is not permitted to assign, sub-license, and novate or
                transfer these Terms or any of the rights licensed to them.
              </li>
            </ol>
          </li>
          <li>
            <h4>Unauthorised, Malicious and/or Illegal Use</h4>
            <ol>
              <li>
                The Company does not authorise the User to do any functionality
                changes or integrations into the Company Software without prior
                consent.
              </li>
              <li>
                The User must not:
                <ol>
                  <li>
                    Use the Software in any unlawful manner, for any unlawful or
                    fraudulent purpose, or in any manner inconsistent with these
                    Terms; or
                  </li>
                  <li>
                    Upload any malicious or unlawful content and/or data onto
                    the Software; or
                  </li>
                  <li>
                    Infringe on the intellectual property rights, or those of
                    any third party, in relation to their use of the Software;
                    or
                  </li>
                  <li>
                    Tamper with or use the Software in a way that could damage,
                    disable, overburden, impair or compromise the Company's
                    systems or security, or interfere with other users' systems
                    or security; or
                  </li>
                  <li>
                    Collect any information or data from the Software and/or
                    Hardware or systems; or
                  </li>
                  <li>
                    Disclose or distribute information relating to another user
                    of the Software to any third party; or
                  </li>
                  <li>
                    Use any other User's information for any marketing purposes
                    unless they have obtained the User's express permission to
                    do so; or
                  </li>
                  <li>
                    Access or register user logins via any automated methods.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <h4>Limitation of Liability</h4>
            <ol>
              <li>
                Nothing in these Terms excludes or limits our liability for:
                <ol>
                  <li>
                    Death or personal injury caused by our negligence; and/or
                  </li>
                  <li>Fraud or fraudulent misrepresentation; and/or</li>
                  <li>
                    Any matter in respect of which it would be unlawful for us
                    to exclude or restrict our liability.
                  </li>
                </ol>
              </li>
              <li>
                Nothing in these terms and conditions will restrict any of your
                statutory rights.
              </li>
              <li>
                The Company shall not be liable for any direct, indirect,
                incidental, special, consequential or exemplary damages,
                including but not limited to, damages for loss of profits,
                business, goodwill, use, data or other intangible and tangible
                losses resulting from:
                <ol>
                  <li>
                    The use or the inability to use the Software, hardware (in
                    whole or in part) or any technical malfunctions; or
                  </li>
                  <li>
                    The unauthorized access to, or alteration of, your
                    communications or data; or
                  </li>
                  <li>
                    The cost of procurement of substitute goods and services; or
                  </li>
                  <li>
                    The conduct or actions of any third party regarding the
                    Software; or
                  </li>
                  <li>Any other matter relating to the Software.</li>
                </ol>
              </li>
              <li>
                The Company does not accept any liability for any delays,
                failures, errors or omissions or loss of transmitted
                information, viruses or other contamination or destructive
                properties transmitted to you or your computer system via our
                Software.
              </li>
              <li>
                Force majeure: We will not be deemed to be in breach of contract
                or of these terms and conditions as a result of any delay in our
                performance or failure to perform our obligations if that delay
                or failure to perform is due to any cause or circumstance beyond
                our reasonable control including, but not limited to, all
                overwhelming and unpreventable events caused directly and
                exclusively by forces of nature that can be neither anticipated,
                nor controlled, nor prevented by the exercise of prudence,
                diligence, and care, including but not limited to: war, riot,
                civil commotion; compliance with any law or governmental order,
                rule, regulation or direction and acts of third parties.
              </li>
              <li>
                If we have contracted to provide orders to more than one
                customer and are prevented from fully meeting our obligations to
                you by reason of an event of Force Majeure, we may decide at our
                absolute discretion which orders we will fill and to what
                extent. Nothing in these Terms obliges us to prioritise any
                order placed by the User in an event of Force Majeure.
              </li>
              <li>
                For the avoidance of doubt, nothing in Clause 28.5 shall excuse
                the Customer from any payment obligations under this agreement.
              </li>
              <li>
                The products sold by Kleo are provided for commercial use only.
                Accordingly, we do not accept liability for any indirect loss,
                consequential loss, loss of data, loss of income or profit, loss
                or damage to property and/or loss from claims of third parties
                arising out of the use of the Software and/or Hardware or for
                any products or services purchased from the Company.
              </li>
              <li>
                We have taken all reasonable steps to prevent Internet fraud and
                ensure any data collected from you is stored as securely and
                safely as possible. However, we cannot be held liable in the
                unlikely event of a breach in our secure computer servers or
                those of third parties.
              </li>
              <li>
                No delay or failure on our part to enforce our rights or
                remedies under the Agreement shall constitute a waiver on our
                part of such rights or remedies unless such waiver is confirmed
                in writing.
              </li>
              <li>
                The User agrees to defend, indemnify and hold harmless Kleo, its
                affiliates and its licensees and licensors, and their employees,
                contractors, agents, officers and directors, from and against
                any and all claims, damages, obligations, losses, liabilities,
                costs or debt, and expenses (including but not limited to
                reasonable legal fees), resulting from or arising out of:
                <ol>
                  <li>our use and access of the Services;</li>
                  <li>Any unauthorised use of your account or password; or</li>
                  <li>Any other breach of the terms of this Agreement.</li>
                </ol>
              </li>
              <li>
                In addition, you will indemnify us and our affiliates against
                any losses, damages, or claims (and all related costs including
                legal fees), penalties, fines, interest, expenses and other
                liabilities resulting from a third-party claim against us or
                affiliate arising from this Agreement and relating to a
                violation or claimed violation of the third party's intellectual
                property rights, where the loss, damages or claim arises from us
                or an affiliate, using or permitting the use of, or being or
                having been the registered proprietor of a domain name, a brand
                name, trademark, logo or other intellectual property which the
                third-party claims violate its own rights, or where it is
                claimed we or an affiliate have assisted or permitted you to use
                or to be a registered proprietor of such rights in violation of
                the third party's own rights.
              </li>
              <li>
                28.13. Other than as set out above, the Company's maximum
                aggregate liability arising out of any losses for paid license
                subscription users under or in connection with these Terms, is
                limited to the total amount of subscription fees paid by the
                User in the previous twelve (12) months.
              </li>
            </ol>
          </li>
          <li>
            <h4>Representation</h4>
            <ol>
              <li>
                The User has the due right and power to enter into this
                Agreement. Entering into this Agreement will not cause a breach
                of any agreement the User has with a third party.
              </li>
              <li>
                {" "}
                By assenting to this Agreement, the User represents and warrants
                that they have the full right, power and authority to access and
                use the Software and, to the extent necessary, that they have
                obtained all necessary corporate or other authorizations or
                consents to access and use the Services. Thus, if you are an
                individual (e.g., employee or consultant) acting on behalf of an
                organization, you represent and warrant that you have obtained
                all necessary authorizations or consents (i.e., you must be an
                authorized representative) to accept these Terms on behalf of
                such Organization so that, in addition to yourself, such
                Organization is bound by these Terms.
              </li>
            </ol>
          </li>
          <li>
            <h4>Severance</h4>
            <ol>
              <li>
                30.1. If any part of these Terms and Conditions is found to be
                invalid by a court of Law, tribunal or another forum of
                competent jurisdiction, or otherwise rendered unenforceable,
                that decision shall not invalidate or void the other parts of
                this Agreement. An amendment of these Terms and Conditions shall
                be deemed to have been undertaken by any modification or
                severing parts of the Terms and Conditions as necessary to
                render them valid, legal and enforceable while preserving their
                sole purpose. Or, if this is not possible, by substituting
                another provision that is valid, legal and enforceable that
                gives equivalent effect to the Parties' intent.
              </li>
            </ol>
          </li>
          <li>
            <h4>Termination of Agreement</h4>
            <ol>
              <li>
                31.1. The Company may decide to cease providing all parts of the
                Software at any time, for any foreseen or unforeseen reasons,
                including, but not limited to, cessation of trading or
                bankruptcy. Nothing in these Terms is to be taken as a guarantee
                that the Software will always be available, either in its
                current form or an updated version.
              </li>
              <li>
                {" "}
                This Agreement may be terminated by either Party subject to a
                thirty (30) days' written notice to the other Party via email.
                <ol>
                  <li>
                    Should the User's billing cycle fall during the thirty (30)
                    days' notice period, a full subscription shall be charged.
                  </li>
                  <li>
                    During the thirty (30) days' notice of termination, the User
                    shall download all data stored on the Cloud Software for
                    their account. After the license subscription is cancelled
                    and this Agreement terminated, all account data will be
                    permanently deleted. There is no possible way to retrieve
                    deleted data.
                  </li>
                  <li>
                    The User reserves the right to withdraw their termination
                    request within the thirty (30) days' notice period and
                    continue the licence subscription without any obstructions.
                  </li>
                </ol>
              </li>
              <li>
                {" "}
                Kleo may decide to terminate this agreement with immediate
                effect, should the User or any of their employees and/or
                associates display any form of bullying, inappropriate, violent,
                aggressive, threatening, intimidating, racist and/or otherwise
                disrespectful behaviour; and/or use any form of offensive,
                aggressive, threatening, racist and/or otherwise disrespectful
                language towards any employee or associate of Kleo.
                <ol>
                  <li>
                    In the event of a termination under this section 31.3, Kleo
                    shall provide the User with an immediate verbal or written
                    notice, and written confirmation within twenty-four (24)
                    hours via email.
                  </li>
                  <li>
                    Once the subscription is terminated, no refunds will be
                    applicable.
                  </li>
                </ol>
              </li>
              <li>
                <h4>
                  Either Party (the “Terminating Party”) may terminate this
                  Agreement immediately if:
                </h4>
                <ol>
                  <li>
                    The other Party files for bankruptcy, receivership,
                    insolvency, reorganisation, dissolution, liquidation, or
                    similar proceedings; or
                  </li>
                  <li>
                    The other Party had a bankruptcy, receivership, insolvency,
                    reorganisation, dissolution, liquidation, or similar
                    proceedings proceeding instituted against it and such
                    proceeding is not dismissed within sixty (60) days; or
                  </li>
                  <li>
                    The other Party makes an assignment for the benefit of its
                    creditors or an offer of settlement, extension or
                    composition to its creditors or an offer of settlement,
                    extension, or composition to its creditors generally; or
                  </li>
                  <li>
                    A trustee, conservator, receiver, or similar fiduciary is
                    appointed for that party or substantially all of the other
                    Party's assets; or
                  </li>
                  <li>
                    The other Party commits any fraud or misrepresentation or
                    engages in any act or omission that may damage the
                    reputation, business, or goodwill of the Terminating Party;
                    or
                  </li>
                  <li>
                    The other Party is found to be involved in any form of child
                    labour, modern slavery, human trafficking, forced and bonded
                    labour and human rights violations, money laundering or
                    terrorist activity; or
                  </li>
                  <li>
                    The other Party breaches any of the provisions of this
                    Agreement.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <h4>Law and Jurisdiction</h4>
            <ol>
              <li>
                Other than as specified in Section 21, any dispute arising out
                of or in connection with this Agreement shall to the extent
                possible be settled amicably by negotiation between the Parties
                within thirty (30) days from the date of written notice by
                either party of the existence of such a dispute. If the Parties
                do not reach settlement within a period of thirty (30) days,
                they will attempt to settle it by mediation before resorting to
                litigation. The Parties shall attempt to agree upon the
                appointment of a mediator, upon receipt, by either of them, of a
                written notice to concur in such appointment. Should the
                mediation fail, in whole or in part, either Party may decide to
                take the dispute or claim to court for final decision.
              </li>
              <li>
                These terms of use and any dispute or claim arising out of or in
                connection with them or their subject matter or formation
                (including non-contractual disputes or claims) shall be governed
                by and construed in accordance with the laws of England and
                Wales. All legal disputes related to card processing are handled
                under laws of England and Wales.
              </li>
            </ol>
          </li>
          <li>
            <h4>Entire Agreement</h4>
            <ol>
              <li>
                This Agreement, together with any supporting documents added in
                the Appendix, constitute the entire Agreement between the
                Company and the User, and supersedes any prior written or oral
                agreement with respect to the subject matter hereof.
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
