// LocationContext.js

import React, { createContext, useContext, useState } from "react";

// Create the context
const LocationContext = createContext();

// Provider component
export const LocationProvider = ({ children }) => {
  const [selectedLocation, setSelectedLocation] = useState(""); // Initialize with default value

  // Define functions to update location data if needed

  return (
    <LocationContext.Provider value={{ selectedLocation, setSelectedLocation }}>
      {children}
    </LocationContext.Provider>
  );
};

// Custom hook to consume the context
export const useLocation = () => {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error("useLocation must be used within a LocationProvider");
  }
  return context;
};
