import React, { createContext, useState, useEffect } from 'react';
import { getApitoken } from '../module/landingScreen';

// Create a Context for the theme
export const ThemeContext = createContext();

// Create a provider component
export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState({
    buttonBgColor: '#00a5a5',
    buttonTextColor: '#ffffff',
    textColor: '#00A5A5',
    dine: '#00a5a5',
    takewayColor: '#c0d83e',
  });
  const [apiTokens, setApiToken] = useState(null); // State to hold API token

  const getAPIToken = async () => {
    try {
      const res = await getApitoken();
      if (res.status === 200) {
        const apiToken = res.data.message.data;
        setApiToken(apiToken);
        setTheme({
            buttonBgColor: apiToken.button_color || '#00a5a5',
            buttonTextColor: apiToken.button_text || '#ffffff',
            textColor: apiToken.text_color || '#00A5A5',
            dine: apiToken.dine_in || '#00a5a5',
            takewayColor: apiToken.takeaway || '#c0d83e',
            kdsbutton: apiToken.kdsbutton_color || '#c0d83e',
        });
      } else {
        console.error("Failed to fetch API token:", res.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getAPIToken();
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty('--button-bg-color', theme.buttonBgColor);
    document.documentElement.style.setProperty('--button-text-color', theme.buttonTextColor);
    document.documentElement.style.setProperty('--text-color', theme.textColor);
    document.documentElement.style.setProperty('--dine', theme.dine);
    document.documentElement.style.setProperty('--takeway-color', theme.takewayColor);
    document.documentElement.style.setProperty('--kds-button-bg-color', theme.kdsbutton);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
