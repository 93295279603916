import React from 'react';
import RelatableItemImage from '../assets/images/relatabeItem.png';

function RelatableItems({ item, onSelect }) {
    return (
        <div className='container'>
            <div className="relatable-item-card text-center mx-auto" onClick={() => onSelect(item)}>
                <div className="card-body">
                    <img src={RelatableItemImage} className='relatable-item-card-image' alt={item.name || 'Relatable Item'} />
                </div>
            </div>
            <span className="d-flex justify-content-center fs-5 fw-semibold mt-2">{item.name}</span>
        </div>
    );
}

export default RelatableItems;
