import axios from "axios";
// import { translator } from "./constants/actionTypes";
import QueryString from "qs";
// const urlPath = window.location.href;
const axiosInstance = axios.create({
  paramsSerializer: (params) =>
    QueryString.stringify(params, { arrayFormat: "repeat" }),
});
axiosInstance.interceptors.request.use(async function (config) {
  config.headers["Content-Type"] = "application/json";
  return config;
});
export default axiosInstance;
