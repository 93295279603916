import React, { useEffect, useState } from "react";
import { Modal, Button, Spin } from "antd";
import NoImgFood from "../assets/images/ItemImage.png"; // Placeholder image for items without image
// import { fetchItemAttributes } from "../module/landingScreen"; // Adjusted import to fetch attributes

function AttributesPopup({
  visible,
  onClose,
  item,
  addToCart,
  cartItems,
  decreaseCartItemQuantity,
  increaseCartItemQuantity,
  removeFromCart,
}) {
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [attributes, setAttributes] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);

  useEffect(() => {
    const getAttributes = async () => {
    if (visible && item) {
      setLoading(true);
      try {
        const storedAttributes = localStorage.getItem(
          `attributes_${item.id}`
        );
        const fetchedAttributes = storedAttributes
          ? JSON.parse(storedAttributes)
          : [];
        setAttributes(fetchedAttributes);
        
      } catch (error) {
        console.error("Error fetching Attributes:", error);
        setAttributes([]);
      } finally {
        setLoading(false);
      }
    }
  };
  if (visible) {
    // Clear selected combo items when popup opens
    setSelectedAttributes([]);
    getAttributes();
  }
}, [visible, item]);


  const calculateTotalPrice = () => {
    let totalPrice = parseFloat(item.product_price) || 0;

    attributes.forEach((attributeGroup, groupIndex) => {
      const groupSelections = selectedAttributes[groupIndex] || [];

      groupSelections.forEach((selectedOption) => {
        totalPrice += parseFloat(selectedOption.price) || 0;
      });
    });

    return totalPrice;
  };

  const handleAddToCart = (event) => {
    event.stopPropagation();

    if (!validateSelections()) {
      return;
    }

    let itemToAdd = { ...item };
    const totalPrice = calculateTotalPrice();

    // Prepare the item to add to cart with selected options
    itemToAdd = {
      ...itemToAdd,
      total_price: totalPrice.toFixed(2),
      selectedAttributes: selectedAttributes,
    };

    localStorage.setItem(
      `selectedAttributes_${item.id}`,
      JSON.stringify(selectedAttributes)
    );
    addToCart(itemToAdd);
  };

  const toggleExpanded = () => setExpanded(!expanded);

  const truncateDescription = (description) => {
    const words = description.split(" ");
    return words.length > 40
      ? words.slice(0, 40).join(" ") + "..."
      : description;
  };

  const renderAllergens = () =>
    item.allergens?.[0]?.map((allergen, index) => (
      <img
        key={index}
        src={allergen.icon}
        alt={allergen.allergens}
        title={allergen.allergens}
        style={{ width: "24px", height: "24px", marginRight: "5px" }}
      />
    ));

  const renderQuantityControls = () => {
    const cartItem = cartItems.find((cartItem) => cartItem.id === item.id);
    return (
      <div className="quantity-controls">
        <Button onClick={() => decreaseCartItemQuantity(item.id)}>-</Button>
        <span>{cartItem?.quantity || 0}</span>
        <Button onClick={() => increaseCartItemQuantity(item.id)}>+</Button>
      </div>
    );
  };

  const handleOptionSelect = (groupIndex, option) => {
    setSelectedAttributes((prevSelectedAttributes) => {
      const newSelectedAttributes = [...prevSelectedAttributes];
  
      // Get the current selection for the group
      const groupSelections = newSelectedAttributes[groupIndex] || [];
  
      // Remove the previously selected item from the cart if it exists
      if (groupSelections.length > 0) {
        removeFromCart(groupSelections[0]);
      }
  
      // Add the new selection to the group
      newSelectedAttributes[groupIndex] = [option];
  
      return newSelectedAttributes;
    });
  };
  

  const parseSelectionRequirement = (attribute) => {
    if (attribute.mandatory === 1) {
      if (attribute.description === "Select 0") {
        return 0;  
      } else {
        const match = attribute.description.match(/Select (\d+)/);
        return match ? parseInt(match[1]) : 0;
      }
    }
    return 0;
  };

  const validateSelections = () => {
    const errors = [];

    attributes.forEach((attributeGroup, groupIndex) => {
      const requiredSelections = parseSelectionRequirement(attributeGroup);
      const groupSelections = selectedAttributes[groupIndex] || [];

      if (
        attributeGroup.mandatory === 1 &&
        groupSelections.length < requiredSelections
      ) {
        errors.push(
          `Please select at least ${requiredSelections} item(s) from ${attributeGroup.name}`
        );
      }
    });

    setValidationErrors(errors);
    return errors.length === 0;
  };

  const numberToWord = (number) => {
    switch (number) {
      case 1:
        return "one";
      case 2:
        return "two";
      case 3:
        return "three";
      // Add more cases as needed for other numbers
      default:
        return `${number}`;
    }
  };
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={900}
      className="attribute-modal"
    >
      <div className="w-100 justify-content-center mb-3">
        <div className="d-flex align-items-stretch card-body">
          <div className="item-card-image">
            <img src={item?.image || NoImgFood} alt="Item" />
          </div>
          {item && (
            <div className="item-card-main">
              <div className="desc-left">
                <div className="card-text">
                  <div>
                    <h4 className="card-title">{item.name}</h4>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: expanded
                          ? item.description
                          : truncateDescription(item.description),
                      }}
                    ></p>
                  </div>
                  {item.description.split(" ").length > 40 && (
                    <Button
                      type="link"
                      onClick={toggleExpanded}
                      style={{
                        position: "absolute",
                        margin: "-35px 10px 0px -15px",
                      }}
                    >
                      {expanded ? "read less" : "read more"}
                    </Button>
                  )}
                </div>
                <div className="item-card-price">
                  <p>
                    £ <span>{item.product_price}</span>
                  </p>
                </div>
              </div>
              <div className="desc-bottom">
                <div className="allergens-icons">{renderAllergens()}</div>
                <div className="attr-qty-control">
                  {cartItems &&
                  cartItems.some((cartItem) => cartItem.id === item.id) ? (
                    renderQuantityControls()
                  ) : (
                    <Button onClick={handleAddToCart} className="popup-add-btn">
                      + Add
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="attribute-list scrollable-content">
          {loading ? (
            <div className="spin-loader">
              <Spin size="large" />
            </div>
          ) : (
            <>
              {attributes.map((attributeGroup, groupIndex) => {
                const requiredSelections =
                  parseSelectionRequirement(attributeGroup);
                const isValidationRequired = attributeGroup.mandatory === 1;
                const showAsterisk = isValidationRequired && requiredSelections > 0;

                return (
                  <div key={groupIndex} className="attr-block">
                    <h4>
                      {attributeGroup.name}
                      {showAsterisk && (
                        <>
                          <span className="asterisk">*</span>
                          {isValidationRequired && requiredSelections > 0 && (
                            <span className="select-text">
                              &nbsp;(
                              <span className="selection-requirement">
                                {` Select Any ${numberToWord(
                                  requiredSelections
                                )}`}
                              </span>
                              )
                            </span>
                          )}
                        </>
                      )}
                    </h4>
                    <div className="attribute-cards">
                      {attributeGroup.options.map((option, optionIndex) => (
                        <div
                        className={`attribute-content ${
                          (selectedAttributes[groupIndex] || []).some(
                            (selectedOption) =>
                              selectedOption.item === option.item
                          )
                            ? "selected"
                            : ""
                        }`}
                        key={optionIndex}
                        onClick={() => handleOptionSelect(groupIndex, option)}
                      >
                          <div className="attr-desc">
                            <span className="attr-name">
                              {option.item_name}
                            </span>
                            <span className="attr-price">£ {option.price}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
        {validationErrors.length > 0 && (
          <div className="validation-errors">
            {validationErrors.map((error, index) => (
              <p key={index} className="text-danger">
                {error}
              </p>
            ))}
          </div>
        )}
        <div className="attr-popup-footer">
          <div>
            <button type="button" className="attr-back-btn" onClick={onClose}>
              Back
            </button>
          </div>
          <div className="attr-total">
            <span className="attr-price">
              ITEM TOTAL <span>£ {calculateTotalPrice().toFixed(2)}</span>
            </span>
            <button className="attr-addToCart-btn" onClick={handleAddToCart}>
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AttributesPopup;
